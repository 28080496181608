/* eslint-disable */

window.tiptapp = window.tiptapp || {};

tiptapp.validation = (function init() {
  const publicInterface = {
    validateOnPublish,
    validateTitle,
    validateCampaignCode,
    validatePrice,
    validatePhone,
    validateAddress,
    validateFromPosition,
    validateFloor,
    validateCode,
    validateSlots,
    validateImages,
    setFieldTouched,
    validateInsurance,
    validateInsuranceEmail,
    validateInsurancePremium,
    validateTermsAgreement,
    validateEqualAddress,
    validateEqualAddressNumber,
    validateEqualAddressStreet,
    validateWasteCategories,
    validateWasteCategoryRules,
  };

  function validateOnPublish() {
    const errors = [];

    const titleValidation = validateTitle('ad-title');
    !titleValidation && errors.push('ad-title-heading');

    const imageValidation = validateImages('image-input');
    !imageValidation && errors.push('images-title');

    const sizeIsChosenValidation = validateSizeIsChosen('price-title');
    !sizeIsChosenValidation && errors.push('price-title');

    const toAddressValidation = validateAddress('to-address');
    !toAddressValidation && errors.push('to-address-anchor');

    const toCodeValidation = validateCode('to-door-code');
    !toCodeValidation && errors.push('to-door-code-anchor');

    const toFloorValidation = validateFloor('to-floor');
    !toFloorValidation && errors.push('to-floor-anchor');

    const toPhoneValidation = validatePhone('toPhone', 'blocket-buyer-phone');
    !toPhoneValidation && errors.push('buyer-phone-anchor');

    const slotValidation = validateSlots();
    if (!slotValidation) {
      errors.push('time-slot-anchor');
    }

    const fromPhoneValidation = validatePhone('fromPhone', 'blocket-seller-phone');
    !fromPhoneValidation && errors.push('seller-phone-anchor');

    const fromAddressValidation = validateAddress('from-address', { allowNumlessPlaces: gVariant === 'store' });
    !fromAddressValidation && errors.push('from-address-anchor');

    const fromPositionValidation = validateFromPosition('from-position');
    !fromPositionValidation && errors.push('from-position-anchor');

    const fromfloorValidation = validateFloor('from-floor');
    !fromfloorValidation && errors.push('from-floor-anchor');

    const priceValidation = validatePrice('ad-price', 'ad-price-container');
    !priceValidation && errors.push('ad-price-container');

    const equalValidation = validateEqualAddress('from-address', 'to-address', { allowNumlessPlaces: gVariant === 'store' });
    !equalValidation && errors.push('from-address-anchor');

    const termsAgreeValidation = validateTermsAgreement();
    !termsAgreeValidation && errors.push('terms-agree-error');

    const captchaValidation = validateCaptcha();
    !captchaValidation && errors.push('captcha-error');

    if (errors.length > 0) {
      animateTo(errors[0]);
      tiptapp.store.error.set(errors[0]);
      return true;
    }

    tiptapp.store.error.remove();

    return false;
  }

  function validateCaptcha() {
    const captchaResponse = grecaptcha.getResponse();

    const element = document.getElementById('');
    if (captchaResponse === '') {
      showFieldError(element, 'captcha-error');
      return false;
    }

    hideFieldError(element, 'captcha-error');
    return true;
  }

  function validateTitle(elementId) {
    const element = document.getElementById(elementId);
    if (!element) return true;
    const passed = /^.{2,25}$/.test(element.value);
    hideFieldError(element);
    !passed && showFieldError(element);
    !passed && $('#title-and-images-edit-form').show();
    return passed;
  }

  function validateCampaignCode(elementId) {
    const element = document.getElementById(elementId);
    if (!element) return true;

    if (element.checkValidity()) {
      hideFieldError(element);
      return true;
    }
    showFieldError(element);
    return false;
  }

  function validateFromPosition(elementId) {
    const element = document.getElementById(elementId);

    if (!element) {
      return true;
    }

    if (element.checkValidity()) {
      hideFieldError(element);
      return true;
    }
    showFieldError(element);
    return false;
  }

  function validatePrice(elementId, secondaryElement) {
    const element = document.getElementById(elementId);
    const element2 = document.getElementById(secondaryElement);
    const isTouched = element.getAttribute('touched');
    const value = parseInt(element.value);
    if (!isTouched || element.checkValidity()) {
      hideFieldError(element2, elementId + '-error');
      return true;
    }
    showFieldError(element2, elementId + '-error');
    return false;
  }

  /* For validation of Swedish mobile phone numbers, see
   * https://www.pts.se/sv/bransch/telefoni/nummer-och-adressering/telefoninummerplanen/telefonnummers-struktur/
   */
  function validatePhone(elementId, secondaryElement) {
    const queryParams = new URLSearchParams(window.location.search);

    const element = document.getElementById(elementId);
    if (!element || element.type === 'hidden' || queryParams.get('skipPhoneValidation')) {
      return true;
    }
    const element2 = document.getElementById(secondaryElement);
    const isTouched = element.getAttribute('touched');

    if (!isTouched) {
      hideFieldError(element2);
      return true;
    }

    if (new RegExp(gMarket.phoneNumberRegexp).test(element.value)) {
      hideFieldError(element2);
      return true;
    }
    showFieldError(element2);
    return false;
  }

  function validateAddress(elementId, { allowNumlessPlaces } = {}) {
    // TODO: rewrite into using validation configuration object.
    allowNumlessPlaces = typeof elementId === 'string' && elementId.match(/from/) && gVariant === 'store';

    const element = document.getElementById(elementId);
    if (!element || element.type === 'hidden') {
      return true;
    }

    let address;

    try {
      address = JSON.parse($(element).attr('data-tiptappAddress'));
    } catch (err) {
      address = getStoredAddress(elementId === 'to-address' ? 'toAddress' : 'fromAddress');
    }

    if (!address || !address.loc || !address.loc.lat || !address.loc.lng) {
      hideFieldError(element);
      showFieldError(element, elementId + '-error');
      return false;
    }

    if (validateAddressStreet(address)) {
      if (!validateAddressNumber(address, { allowNumlessPlaces })) {
        hideFieldError(element);
        showFieldError(element, elementId + '-street-number-error');
        if (gMarket.countryCode === 'GB') {
          setCaretPosition(elementId, 0, { beforeSpace: true });
        } else {
          setCaretPosition(elementId, address.street.length);
        }
        return false;
      }
      if (gMarket.countryCode !== 'DE' && gMarket.countryCode !== 'PT' && gMarket.countryCode !== 'GB' && !validateZipcode(address)) {
        showFieldError(element);
        return false;
      }
      hideFieldError(element, elementId + '-street-number-error');
      hideFieldError(element);
      return true;
    }
    hideFieldError(element, elementId + '-street-number-error');
    showFieldError(element);
    return false;
  }

  function validateAddressNumber(address, { allowNumlessPlaces } = {}) {
    if (address && ((allowNumlessPlaces && address.raw_components && address.raw_components.streetNo == null) || address.streetNo != null)) {
      return true;
    }
    return false;
  }

  function validateAddressStreet(address) {
    if (address && address.street) {
      return true;
    }
    return false;
  }

  function validateZipcode(address) {
    if (address && address.zip) {
      return true;
    }
    return false;
  }

  function validateEqualAddressNumber(fromAddress, toAddress) {
    return fromAddress && toAddress && fromAddress.streetNo === toAddress.streetNo;
  }

  function validateEqualAddressStreet(fromAddress, toAddress) {
    return (
      validateAddressStreet(fromAddress) && validateAddressStreet(toAddress) && fromAddress.street.toLowerCase() === toAddress.street.toLowerCase()
    );
  }

  function validateEqualAddress(fromElementId, toElementId, { allowNumlessPlaces } = {}) {
    allowNumlessPlaces = typeof elementId === 'string' && elementId.match(/from/) && gVariant === 'store';

    const fromElement = document.getElementById(fromElementId);
    const toElement = document.getElementById(toElementId);
    if (!fromElement || !toElement) {
      return true;
    }

    let fromAddress;
    let toAddress;

    try {
      fromAddress = JSON.parse($(fromElement).attr('data-tiptappAddress'));
      toAddress = JSON.parse($(toElement).attr('data-tiptappAddress'));
    } catch (err) {
      fromAddress = getStoredAddress('fromAddress');
      toAddress = getStoredAddress('toAddress');
    }

    if (validateEqualAddressStreet(fromAddress, toAddress) && (allowNumlessPlaces || validateEqualAddressNumber(fromAddress, toAddress))) {
      showFieldError(null, `equal-address-error`);
      return true;
    }
    hideFieldError(null, `equal-address-error`);
    return true;
  }

  function getStoredAddress(addressStorageKey) {
    const address = tiptapp.store.address.get(addressStorageKey);
    if (address) {
      return address;
    }
    return {};
  }

  function validateFloor(elementId) {
    const element = document.getElementById(elementId);
    if (!element) {
      return true;
    }
    if (element.value == null || element.value.length === 0) {
      hideFieldError(element);
      return true;
    }
    const number = parseInt(element.value);
    if (number > -5 && number < 101) {
      hideFieldError(element);
      return true;
    }
    showFieldError(element);
    return false;
  }

  function validateCode(elementId) {
    const element = document.getElementById(elementId);
    if (!element) {
      return true;
    }
    if (!element.value || element.value.length <= 125) {
      hideFieldError(element);
      return true;
    }
    showFieldError(element);
    return false;
  }

  function validateSizeIsChosen(elementId) {
    var element = document.getElementById(elementId);
    if (!element) return true;

    const hasPickedSize = tiptapp.blocket.getSelectedPriceBox();
    if (hasPickedSize) {
      hideFieldError(element);
      return true;
    }
    showFieldError(element);
    return false;
  }

  function validateSlots() {
    if ($('#slot-container-today').length || $('#slot-container-tomorrow').length) {
      if (tiptapp.form.timeSlots.getSelected().length === 0) {
        $('#min-time-slot-text').css('color', '#ee0c28');
        return false;
      }

      $('#min-time-slot-text').css('color', '#383838');
    }
    return true;
  }

  function validateImages(elementClassName) {
    const imageElements = [].slice.call(document.getElementsByClassName(elementClassName));
    const externalImages = gExternalAd && gExternalAd.images && gExternalAd.images.length;
    const inputImages = imageElements.reduce((files, elm) => {
      elm.files[0] && files.push(elm.files[0]);
      return files;
    }, []);
    const storedImages = imageElements
      .filter(elm => elm.id)
      .map(elm => `adImage${elm.id.replace('image-input-', '')}`)
      .map(tiptapp.store.getItem)
      .reduce((urls, url) => {
        url && urls.push(url);
        return urls;
      }, []);

    const countValidated = tiptapp.imageupload.getImagesCount() <= tiptapp.maxNumberOfImages;

    const validated = (externalImages || imageElements.length === 0 || inputImages.length || storedImages.length) && countValidated;

    const errorElement = document.getElementById('image-missing-error');
    const errorElementTooMany = document.getElementById('image-too-many-error');
    errorElement && errorElement.classList.add('hide');
    errorElementTooMany && errorElementTooMany.classList.add('hide');

    !validated && !countValidated && errorElementTooMany && errorElementTooMany.classList.remove('hide');
    !validated && countValidated && errorElement && errorElement.classList.remove('hide');
    !validated && $('#title-and-images-edit-form').show();

    return validated;
  }

  function validateInsurance() {
    const errors = [];

    if (!validateInsurancePremium()) {
      errors.push('insurance-premium-container');
    }

    if (!validateInsuranceEmail()) {
      errors.push('insurance-user-email');
    }

    if (errors.length > 0) {
      animateTo(errors[0]);
      tiptapp.store.error.set(errors[0]);
      return true;
    }

    tiptapp.store.error.remove();
    return false;
  }

  function validateInsurancePremium() {
    const element = document.querySelector('input[name=insurancePremiumCents]:checked');
    if (!element || !element.hasAttribute('value') || !element.hasAttribute('data-insured-amount')) {
      showFieldError(element, 'insurance-premium-error');
      return false;
    }
    hideFieldError(element, 'insurance-premium-error');
    return true;
  }

  function validateInsuranceEmail() {
    const element = document.querySelector('#insurance-user-email');
    if (element.checkValidity()) {
      hideFieldError(element);
      return true;
    }
    showFieldError(element);
    return false;
  }

  function validateTermsAgreement() {
    const element = document.getElementById('agreeToTerms');
    if (!element) return true;

    if (!document.querySelector('#agreeToTerms:checked')) {
      showFieldError(element, 'terms-agree-error');
      return false;
    }

    hideFieldError(element, 'terms-agree-error');
    return true;
  }

  function validateWasteCategories() {
    const errors = [];

    const elements = document.querySelectorAll('input.wasteCategory:not([disabled]):checked');
    const firstWasteCategory = document.querySelector('input.wasteCategory');

    if (elements.length === 0) {
      errors.push('waste-categories-error-scroll-target');
      showFieldError(firstWasteCategory, 'waste-categories-error');
    }

    if (errors.length > 0) {
      animateTo(errors[0]);
      tiptapp.store.error.set(errors[0]);
      return true;
    }

    hideFieldError(firstWasteCategory, 'waste-categories-error');

    tiptapp.store.error.remove();
    return false;
  }

  function validateWasteCategoryRules() {
    const errors = [];

    $('.waste-category-rule-options-error').addClass('hide');

    const rulesMissingOptions = $('.waste-category-rule')
      .map(function() {
        const ruleOptionId = this.id.replace('wcr_', '');
        const optionField = $(`.waste-category-rule input[name=wco_${ruleOptionId}]`);
        const val = $(`.waste-category-rule input[name=wco_${ruleOptionId}]:checked`).val();
        if (optionField.length && !val) {
          return this.id;
        }
      })
      .get();

    if (rulesMissingOptions.length) {
      errors.push(rulesMissingOptions[0]);
      rulesMissingOptions.forEach(ruleId => showFieldError(document.getElementById(ruleId)));
    }
    
    const termsCheckboxes = $('.waste-category-rule input.termsCheckBox + label');
    const firstTermsNotChecked = $('.waste-category-rule-terms:not(:hidden) input.termsCheckBox:not([disabled]):not(:checked) + label');

    if (firstTermsNotChecked.length > 0) {
      errors.push(firstTermsNotChecked[0].id);
      showFieldError(firstTermsNotChecked[0]);
    } else {
      termsCheckboxes.each(function () {
        hideFieldError(this);
      })
    }

    if (errors.length > 0) {
      animateTo(errors[0]);
      tiptapp.store.error.set(errors[0]);
      return true;
    }

    tiptapp.store.error.remove();
    return false;
  }

  function showFieldError(element, overrideErrorElement) {
    if (element) {
      element.style.borderColor = '#ee0c28';
    }
    const errorElement = document.getElementById(overrideErrorElement ? overrideErrorElement : element.id + '-error');
    if (errorElement) {
      errorElement.classList.remove('hide');
    }
  }
  function hideFieldError(element, overrideErrorElement) {
    if (element) {
      element.style.borderColor = '#ebebeb';
    }
    const errorElement = document.getElementById(overrideErrorElement ? overrideErrorElement : element.id + '-error');
    if (errorElement) errorElement.classList.add('hide');
  }

  function setFieldTouched(element) {
    if (element.value.length > 0) {
      element.setAttribute('touched', 'true');
    }
    return true;
  }

  function animateTo(elementId) {
    $([document.documentElement, document.body]).animate(
      {
        scrollTop: $('#' + elementId).offset().top,
      },
      500
    );
  }

  function setCaretPosition(elemId, caretPos, { beforeSpace } = {}) {
    let elem = document.getElementById(elemId);
    // Make sure it's >= 0.
    let newPos = Math.max(0, beforeSpace ? caretPos - 1 : caretPos + 1);

    if (elem != null) {
      // let currentValue = elem.value;
      // let newValue = currentValue.insert(caretPos, " ");
      // elem.value = newValue;
      if (elem.createTextRange) {
        let range = elem.createTextRange();
        range.move('character', newPos);
        range.select();
      } else {
        if (elem.selectionStart) {
          elem.focus();
          elem.setSelectionRange(newPos, newPos);
        } else elem.focus();
      }

      // INFO: This is an OLX test-hack for now.
      // if (gVariant === 'olx') {
      //  promptForStreetNumber(elem);
      // }
    }
  }

  function selectFirstAddressItemInList(addressElement) {
    addressElement.blur();
  }

  function promptForStreetNumber(addressElement) {
    // For now this seems to not work on Chrome mobile (both on iOS and Android).
    // if (/Mobi/i.test(navigator.userAgent) && /CriOS|Chrome/i.test(navigator.userAgent)) {
    //   return;
    // }
    pingHotjarWithStateChange('prompt-street-number');
    const streetNumber = prompt(i18next.t('which_street_number'));
    pingHotjarWithStateChange('prompt-street-number-done');
    if (/\d+/.test(streetNumber)) {
      addressElement.setRangeText(streetNumber);
      setTimeout(() => {
        selectFirstAddressItemInList(addressElement);
      }, 600);
    }
  }

  String.prototype.insert = function(index, string) {
    if (index > 0) return this.substring(0, index) + string + this.substring(index, this.length);

    return string + this;
  };

  return publicInterface;
})();
