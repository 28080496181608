/**
 * Simple Blocket Web Storage setup.
 */

window.tiptapp = window.tiptapp || {};

tiptapp.store = (function init() {
  const prefix = String(gExternalAd.id);

  const publicInterface = {
    prefix,

    getItem: key => {
      const item = sessionStorage.getItem(`${prefix}|${key}`);
      try {
        return JSON.parse(item);
      } catch (error) {
        return item;
      }
    },
    setItem: (key, newValue, options = {}) => sessionStorage.setItem(`${prefix}|${key}`, options.raw ? newValue : JSON.stringify(newValue)),
    removeItem: key => sessionStorage.removeItem(`${prefix}|${key}`),

    getLocalItem: key => {
      const item = localStorage.getItem(`${prefix}|${key}`);
      try {
        return JSON.parse(item);
      } catch (error) {
        return item;
      }
    },
    setLocalItem: (key, newValue, options = {}) => localStorage.setItem(`${prefix}|${key}`, options.raw ? newValue : JSON.stringify(newValue)),
    removeLocalItem: key => localStorage.removeItem(`${prefix}|${key}`),

    /**
     * Fetch all stored form items.
     *
     * @param {RegExp} elementNameFilterRegex Optional `RegExp` object that will filter which
     *   elements that should be returned, it filters on element names.
     * @returns {Array} An array with all the specified elements that are currently stored.
     */
    getFormItems: elementNameFilterRegex => {
      const result = [];
      const formFieldPrefix = `${prefix}|form|`;
      const regex = new RegExp(`^${formFieldPrefix.replace(/\|/g, '\\|')}`);
      Object.keys(sessionStorage)
        .filter(ith => regex.test(ith))
        .map(ith => {
          const [type, name] = ith.substring(formFieldPrefix.length).split('|');
          return { key: ith, type, name };
        })
        .filter(ith => (elementNameFilterRegex ? elementNameFilterRegex.test(ith.name) : true))
        .forEach(ith => {
          const json = sessionStorage.getItem(ith.key);
          if (!ith.type || !ith.name || !json) {
            return;
          }
          try {
            const value = JSON.parse(json);
            result.push({ ...ith, value });
          } catch (error) {
            if (!(error instanceof SyntaxError)) {
              throw error;
            }
            console.warn(`Serialized form value was not correctly formatted JSON, key: ${ith.key} value: ${json}`, error); // eslint-disable-line
          }
        });

      return result;
    },

    ad: {
      get: () => tiptapp.store.getItem('ad') || {},
      set: newValue => tiptapp.store.setItem('ad', newValue),
      remove: () => tiptapp.store.removeItem('ad'),
      updateInsurance: (premiumCents, insuredAmountCents) => {
        const ad = tiptapp.store.getItem('ad') || {};
        ad.insurance = {
          premiumCents,
          insuredAmountCents,
        };
        tiptapp.store.ad.set(ad);
      },
      updateWasteSorted: wasteSorted => {
        const ad = tiptapp.store.getItem('ad') || {};
        ad.wasteSorted = wasteSorted;
        tiptapp.store.ad.set(ad);
      },
      updateWasteCategoryIds: wasteCategoryIds => {
        const ad = tiptapp.store.getItem('ad') || {};
        ad.wasteCategoryIds = wasteCategoryIds;
        tiptapp.store.ad.set(ad);
      },
      updateWasteCategoryRules: wasteCategoryRules => {
        const ad = tiptapp.store.getItem('ad') || {};
        ad.wasteCategoryRules = wasteCategoryRules;
        tiptapp.store.ad.set(ad);
      },
      updatePhoneNumber: (direction, number) => {
        const ad = tiptapp.store.getItem('ad') || {};
        if (!ad[direction]) {
          ad[direction] = {};
        }
        if (!ad[direction].contactInfo) {
          ad[direction].contactInfo = {};
        }
        ad[direction].contactInfo.phone = number;
        tiptapp.store.ad.set(ad);
      },
    },

    address: {
      get: name => tiptapp.store.getItem(`address|${name}`),
      set: (name, newValue) => tiptapp.store.setItem(`address|${name}`, newValue),
      remove: name => tiptapp.store.removeItem(`address|${name}`),
    },

    dynamicLink: {
      get: () => tiptapp.store.getItem('dynamicLink'),
      set: newValue => tiptapp.store.setItem('dynamicLink', newValue),
      remove: () => tiptapp.store.removeItem('dynamicLink'),
    },

    email: {
      get: () => tiptapp.store.getItem('email'),
      set: newValue => tiptapp.store.setItem('email', newValue),
      remove: () => tiptapp.store.removeItem('email'),
    },

    error: {
      get: () => tiptapp.store.getItem('error'),
      set: newValue => tiptapp.store.setItem('error', newValue),
      remove: () => tiptapp.store.removeItem('error'),
    },

    input: {
      get: id => tiptapp.store.getItem(`form|${id}`),
      set: (id, newValue) => tiptapp.store.setItem(`form|${id}`, newValue),
      remove: id => tiptapp.store.removeItem(`form|${id}`),
    },

    phone: {
      get: () => tiptapp.store.getItem('phone'),
      set: newValue => tiptapp.store.setItem('phone', newValue),
      remove: () => tiptapp.store.removeItem('phone'),
    },

    state: {
      get: () => tiptapp.store.getItem('state'),
      set: newValue => tiptapp.store.setItem('state', newValue),
      remove: () => tiptapp.store.removeItem('state'),
    },

    timeSlotDay: {
      get: () => tiptapp.store.getItem('timeSlotDay'),
      set: newValue => tiptapp.store.setItem('timeSlotDay', newValue),
      remove: () => tiptapp.store.removeItem('timeSlotDay'),
    },

    timeSlots: {
      clear: () => {
        const regex = /\|timeSlot_/;
        Object.keys(sessionStorage)
          .filter(ith => regex.test(ith))
          .forEach(ith => sessionStorage.removeItem(ith));
      },
      remove: name => tiptapp.store.removeItem(`form|checkbox|${name}`),
    },

    user: {
      get: () => tiptapp.store.getItem('user'),
      set: newValue => tiptapp.store.setItem('user', newValue),
      remove: () => tiptapp.store.removeItem('user'),
    },

    wasteCategoryRules: {
      get: () => tiptapp.store.getItem('wasteCategoryRules'),
      set: newValue => tiptapp.store.setItem('wasteCategoryRules', newValue),
      remove: () => tiptapp.store.removeItem('wasteCategoryRules'),
    },
  };

  return publicInterface;
})();
