jQuery(function cookie($) {
  checkCookieEu();

  function checkCookieEu() {
    const consent = getCookieEu('cookies_consent');

    if ((consent == null || consent === '' || consent === undefined) && $('#cookie-consent-bar').length) {
      // show notification bar
      $('#cookie-consent-bar').show();

      // stick cookie consent on top
      const elTop = $('#cookie-consent-bar').offset().top;
      const elHeight = $('#cookie-consent-bar').height();

      $(window).scroll(function onScroll() {
        if ($(window).scrollTop() > elTop) {
          $('#cookie-consent-bar').css('position', 'fixed');
          if ($('#cookie-consent-bar').is(':visible')) {
            $('#blocket-header').css('padding-top', `${elHeight}px`);
          }
        } else {
          $('#cookie-consent-bar').css('position', 'relative');
          $('#blocket-header').css('padding-top', '0');
        }
      });
    }
  }

  function setCookieEu(cName, value, exdays) {
    const exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    const cValue = escape(value) + (exdays == null ? '' : `; expires=${exdate.toUTCString()}`);
    document.cookie = `${cName}=${cValue}; path=/`;

    $('#cookie-consent-bar').hide();
    $('#blocket-header').css('padding-top', '0');
  }

  function getCookieEu(cName) {
    let x;
    let y;
    const ARRcookies = document.cookie.split(';');
    for (let i = 0; i < ARRcookies.length; i += 1) {
      x = ARRcookies[i].substr(0, ARRcookies[i].indexOf('='));
      y = ARRcookies[i].substr(ARRcookies[i].indexOf('=') + 1);
      x = x.replace(/^\s+|\s+$/g, '');
      if (x === cName) {
        return unescape(y);
      }
    }
    return '';
  }

  $('#cookie_accept').click(function accept() {
    setCookieEu('cookies_consent', 1, 30);
    $('#cookie-consent-bar').trigger('hide');
  });
});
