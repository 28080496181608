/**
 * Blocket ad price recommendation algorithm.
 */

window.tiptapp = window.tiptapp || {};

tiptapp.recommendedPriceGB = (function init() {
  const publicInterface = {
    setRecommendedPrice,
  };

  let previousPrice = null;

  let Size = {
    h2t: {
      S: {
        basePrice: 20,
        elevatorStaticCost: 1,
        floorStaticCost: 0,
        // floorCostNoElevator: 0.02, // => 3 per floor
        distanceWeight: 0.02,
        name: 'S',
      },
      M: {
        basePrice: 30,
        elevatorStaticCost: 0,
        floorStaticCost: 1,
        // floorCostNoElevator: 0.04, // => 9.2 per floor
        name: 'M',
      },
      L: {
        basePrice: 45,
        elevatorStaticCost: 1,
        floorStaticCost: 2,
        // floorCostNoElevator: 0.06, // => 22.2 per floor
        name: 'L',
      },
      XL: {
        basePrice: 80,
        elevatorStaticCost: 3,
        floorStaticCost: 5,
        // floorCostNoElevator: 0.08, // => 56 per floor
        name: 'XL',
      },
    },
    b4m: {
      S: {
        basePrice: 10, // 200, // 150,
        elevatorStaticCost: 1,
        floorStaticCost: 0,
        // floorCostNoElevator: 0.02, // => 3 per floor
        distanceWeight: 0.02,
        name: 'S',
      },
      M: {
        basePrice: 12, // 300, // 230,
        elevatorStaticCost: 0,
        floorStaticCost: 1,
        // floorCostNoElevator: 0.04, // => 9.2 per floor
        name: 'M',
      },
      L: {
        basePrice: 17, // 370,
        elevatorStaticCost: 1,
        floorStaticCost: 2,
        // floorCostNoElevator: 0.06, // => 22.2 per floor
        name: 'L',
      },
      XL: {
        basePrice: 20, // 800, // 700,
        elevatorStaticCost: 3,
        floorStaticCost: 5,
        // floorCostNoElevator: 0.08, // => 56 per floor
        name: 'XL',
      },
    },
    payAway: {
      S: {
        basePrice: 28,
        elevatorStaticCost: 1,
        floorStaticCost: 0,
        // floorCostNoElevator: 0.02, // => 3 per floor
        distanceWeight: 0.02,
        name: 'S',
      },
      M: {
        basePrice: 37,
        elevatorStaticCost: 0,
        floorStaticCost: 1,
        // floorCostNoElevator: 0.04, // => 9.2 per floor
        name: 'M',
      },
      L: {
        basePrice: 78,
        elevatorStaticCost: 1,
        floorStaticCost: 2,
        // floorCostNoElevator: 0.06, // => 22.2 per floor
        name: 'L',
      },
      XL: {
        basePrice: 127,
        elevatorStaticCost: 3,
        floorStaticCost: 5,
        // floorCostNoElevator: 0.08, // => 56 per floor
        name: 'XL',
      },
    },
  };

  function setRecommendedPrice(recommendedPrice = {}) {
    const values = tiptapp.util.flatten(recommendedPrice, '_');

    const isValid = Object.keys(values).reduce(
      (acc, key) => acc && (key.indexOf('_name') !== -1 || (typeof values[key] === 'number' && Number.isFinite(values[key]))),
      true
    );

    if (isValid) {
      _.merge(Size, recommendedPrice);
    }
  }
  /**
   * Since we calculate distances using a strait line, we need to compensate
   * a bit so that they will be more alike real driving distances.
   */
  const DistanceCompensation = {
    5000: 1.7,
    7000: 1.6,
    9000: 1.5,
    10000: 1.4,
    15000: 1.2,
    20000: 1.1,
  };
  DistanceCompensation.distances = Object.keys(DistanceCompensation);

  /**
   * Default price size bracket that will be used if no size has been specified.
   */
  publicInterface.defaultSize = Size.h2t.M;

  /**
   * Calculate the recommended price.
   *
   * @param {object} sizeBracket The size bracket to base calculations on.
   * @param {number} pickUpLat Latitude coordinate of the pick up location.
   * @param {number} pickUpLng Longitude coordinate of the pick up location.
   * @param {number} dropOffLat Latitude coordinate of the drop off location.
   * @param {number} dropOffLng Longitude coordinate of the drop off location.
   * @param {number} pickUpFloor The floor number.
   * @param {boolean} pickUpElevator `true` if the elevator can be used, else `false`.
   * @param {number} dropOffFloor The floor number.
   * @param {boolean} dropOffElevator `true` if the elevator can be used, else `false`.
   * @param {number} numTimeSlots The number of time slots that has been selected.
   */
  publicInterface.calculate = (
    adType,
    sizeBracket,
    pickUpLat,
    pickUpLng,
    dropOffLat,
    dropOffLng,
    pickUpFloor,
    pickUpHasElevator,
    dropOffFloor,
    dropOffHasElevator,
    numTimeSlots
  ) => {
    let size = typeof sizeBracket === 'string' ? Size[adType][sizeBracket] : sizeBracket;
    if (!size) {
      // eslint-disable-next-line
      console.warn(`WARNING: recommendedPrice.calculate() unknown size "${size}" using ${publicInterface.defaultSize.name}`);
      size = Size[adType][publicInterface.defaultSize.name];
    }

    let addedCost = 0;
    addedCost += deliveryDistanceCost(size, pickUpLat, pickUpLng, dropOffLat, dropOffLng);
    // addedCost += distanceFromLondonCost(size, pickUpLat, pickUpLng);
    addedCost += floorCost(size, pickUpFloor, pickUpHasElevator);
    addedCost += floorCost(size, dropOffFloor, dropOffHasElevator);
    addedCost += numberOfTimeSlotsCost(size, numTimeSlots);

    // console.log('+deliveryDistanceCost', deliveryDistanceCost(size, pickUpLat, pickUpLng, dropOffLat, dropOffLng));
    // console.log('+distanceFromLondonCost', distanceFromLondonCost(size, pickUpLat, pickUpLng));
    // console.log('+floorCost pickUp', floorCost(size, pickUpFloor, pickUpHasElevator));
    // console.log('+floorCost dropOff', floorCost(size, dropOffFloor, dropOffHasElevator));
    // console.log('+numberOfTimeSlotsCost', numberOfTimeSlotsCost(size, numTimeSlots));

    const price = size.basePrice + addedCost;

    return Math.min(price.toFixed(), 250);
  };

  /**
   * Update the current recommended price.
   */
  publicInterface.update = () => {
    const form = tiptapp.form;

    const fromAddress = form.fromAddress.value() || {};
    if (!fromAddress.loc) {
      fromAddress.loc = {};
    }
    const toAddress = form.toAddress.value() || {};
    if (!toAddress.loc) {
      toAddress.loc = {};
    }
    const priceGroup = form.priceGroup.value();
    const numTimeSlots = tiptapp.form.timeSlots.exists() && (tiptapp.form.timeSlots.getSelected() || []).length;

    const fromFloor = parseInt(form.fromFloor.value() || 0, 10);
    const toFloor = parseInt(form.toFloor.value() || 0, 10);
    const fromElevator = Boolean(form.fromElevator.value());
    const toElevator = Boolean(form.toElevator.value());

    const adType = getAdTypeFromVariant(gVariant);

    const price = publicInterface.calculate(
      adType,
      priceGroup,
      fromAddress.loc.lat,
      fromAddress.loc.lng,
      toAddress.loc.lat,
      toAddress.loc.lng,
      fromFloor,
      fromElevator,
      toFloor,
      toElevator,
      numTimeSlots
    );

    $('#recommended-price').text(price);
    $('#ad-price').val(price);
    $('#recommended-price-algo').val('GB');

    if (previousPrice !== price) {
      previousPrice = price;
      return true;
    }

    previousPrice = price;

    return false;
  };

  /**
   * Returns the shortest distance between the two coordinates (in meters.)
   *
   * @param {number} lat1 First latitude coordinate.
   * @param {number} lng1 First longitude coordinate.
   * @param {number} lat2 Second latitude coordinate.
   * @param {number} lng2 Second latitude coordinate.
   */
  function metersBetween(size, lat1, lng1, lat2, lng2) {
    if (typeof lat1 !== 'number' || typeof lng1 !== 'number' || typeof lat2 !== 'number' || typeof lng2 !== 'number') {
      return NaN;
    }
    const earthDiameter = 12745600;
    const degToRadFactor = Math.PI / 180;
    const lat1rad = lat1 * degToRadFactor;
    const lon1rad = lng1 * degToRadFactor;
    const lat2rad = lat2 * degToRadFactor;
    const lon2rad = lng2 * degToRadFactor;

    const dlat = (lat2rad - lat1rad) / 2;
    const dlon = (lon2rad - lon1rad) / 2;
    const a = Math.sin(dlat) * Math.sin(dlat) + Math.sin(dlon) * Math.sin(dlon) * Math.cos(lat1rad) * Math.cos(lat2rad);

    const meters = earthDiameter * Math.asin(Math.sqrt(a));
    return compensateDistance(meters);
  }

  /**
   * Compensates the given distance for the fact that it has been
   * calculated using a strait line between two locations. The returned
   * distance will be more alike a real driving distance.
   *
   * @param {number} meters The distance in meters to compensate for not being the real driving route.
   * @returns {number} The simulated driving distance in meters.
   */
  function compensateDistance(meters) {
    for (let ith = 0; ith < DistanceCompensation.distances.length; ith += 1) {
      const distance = DistanceCompensation.distances[ith];
      if (meters < distance) {
        return meters * DistanceCompensation[distance];
      }
    }
    return meters;
  }

  /**
   * Calculates the additional cost for the given distance.
   *
   * @param {number} pickUpLat Latitude coordinate of the pick up location.
   * @param {number} pickUpLng Longitude coordinate of the pick up location.
   * @param {number} dropOffLat Latitude coordinate of the drop off location.
   * @param {number} dropOffLng Longitude coordinate of the drop off location.
   */
  function deliveryDistanceCost(size, pickUpLat, pickUpLng, dropOffLat, dropOffLng) {
    const meters = metersBetween(size, pickUpLat, pickUpLng, dropOffLat, dropOffLng);
    const cutoff = 5000;
    if (Number.isNaN(meters) || meters <= cutoff) {
      return 0;
    }
    return (meters - cutoff) * 0.005;
  }

  /**
   * Calculates the additional cost for how far out from the center of Location the location is.
   *
   * @param {number} pickUpLat Latitude coordinate of the pick up location.
   * @param {number} pickUpLng Longitude coordinate of the pick up location.
   */
  function distanceFromLondonCost(size, pickUpLat, pickUpLng) {
    const centralLondonLat = 51.507528;
    const centralLondonLng = -0.127791;
    const meters = metersBetween(size, centralLondonLat, centralLondonLng, pickUpLat, pickUpLng);
    const cutoff = 15000;
    if (Number.isNaN(meters) || meters <= cutoff) {
      return 0;
    }
    return (meters - cutoff) * 0.004;
  }

  /**
   * Calculates the additional cost for the given floor.
   *
   * @param {object} size The size bracket to base calculations on.
   * @param {number} floor The floor number.
   * @param {boolean} elevator `true` if the elevator can be used, else `false`.
   */
  function floorCost(size, floor, elevator) {
    if (!floor) {
      return 0;
    }

    if (elevator) {
      return size.elevatorStaticCost;
    }

    // return size.basePrice * size.floorCostNoElevator * floor;
    return Math.abs(floor) * size.floorStaticCost;
  }

  /**
   * Calculates the additional cost for the given number of time slots.
   *
   * @param {object} size The size bracket to base calculations on.
   * @param {number} numTimeSlots The number of time slots that has been selected.
   */
  function numberOfTimeSlotsCost(size, numTimeSlots) {
    if (typeof numTimeSlots !== 'number' || numTimeSlots > 1) {
      return 0;
    }

    return size.basePrice * 0.1;
  }

  return publicInterface;
})();

function getAdTypeFromVariant(gVariant) {
  switch (gVariant) {
    case 'coop':
      return 'b4m';
    case 'tapaway':
      return 'payAway';
    default:
      return 'h2t';
  }
}
