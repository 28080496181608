window.tiptapp = window.tiptapp || {};
tiptapp.views = tiptapp.views || {};

tiptapp.views.loginSuccess = (() => {
  const container = $('#login-success');
  const countOfHelpers = container.find('.helper-count');
  const requests = container.find('.request-container');
  const helperSpinner = container.find('#helper-spinner');
  const helperRequestText = container.find('.helper-count-text');

  let listeningId;

  // private functions
  function refreshAdStatus(ad, jwt) {
    $.ajax({
      type: 'GET',
      url: `/api/status/${ad._id}`,
      headers: { authorization: jwt },
      contentType: 'application/json',
    })
      .done(data => {
        if (data && data.numberOfHelpers > 0) {
          countOfHelpers.text(data.numberOfHelpers);
          countOfHelpers.show();
          if (data.numberOfHelpers > 1) {
            helperRequestText.html(i18next.t('new_requests'));
          } else {
            helperRequestText.html(i18next.t('new_request'));
          }
          requests.show();
          helperSpinner.hide();
        }
      })
      .fail(error => {
        console.log('error fetching ad', error);
      });
  }

  function createRefreshJob(ad) {
    if (!ad || Object.keys(ad).length === 0) {
      return {
        disabled: true,
        reason: 'ad not found',
      };
    }

    if (typeof ad.created !== 'number') {
      return {
        disabled: false,
        times: 12,
        interval: 5 * 1000,
      };
    }

    const timeElapsed = ad.created - Date.now();

    if (ad.state === 2) {
      return {
        disabled: true,
        reason: 'helper already assigned to ad',
      };
    }

    if (timeElapsed > 2 * 60 * 1000) {
      return {
        disabled: false,
        times: 5,
        interval: 30 * 1000,
      };
    }

    if (timeElapsed > 60 * 1000) {
      return {
        disabled: false,
        times: 5,
        interval: 10 * 1000,
      };
    }

    return {
      disabled: false,
      times: 12,
      interval: 5 * 1000,
    };
  }

  function unlistenForHelpers() {
    if (!listeningId) return;

    clearInterval(listeningId);
    listeningId = undefined;
  }

  function listenForHelpers() {
    if (listeningId) return;

    const ad = tiptapp.store.ad.get();
    const job = createRefreshJob(ad);
    console.log('job will run with the following settings', job);
    const user = tiptapp.getUser();

    if (job.disabled) {
      console.error('job is disabled due to: ', job.reason);
      return;
    }

    if (!user) {
      console.error('job is disabled due to user missing in sessionStore', user);
      return;
    }

    let times = 0;

    listeningId = setInterval(() => {
      if (times > job.times) {
        unlistenForHelpers();
        listenForHelpers();
      } else {
        refreshAdStatus(ad, user.jwt);
        times += 1;
      }
    }, job.interval);
  }

  // Fire the dynamic link
  $('#dynamic-link').click(() => {
    const dynamicLink = tiptapp.store.dynamicLink.get();

    if (/iPhone|iPad|iPod/.test(navigator.platform)) {
      clipboard.writeText(dynamicLink).then(
        function() {
          console.log('success!');
        },
        function() {
          console.log('error!');
        }
      );
    }

    window.open(dynamicLink, '_blank');
    tiptapp.analytics.trackEvent('web_tap_dynamic_link');
  });

  // public functions
  return {
    container,
    display() {
      container.css('display', 'flex');
      // listenForHelpers();
    },
    hide() {
      // unlistenForHelpers();
    },
  };
})();
