/**
 * Image upload functionality.
 */

window.tiptapp = window.tiptapp || {};

tiptapp.autocomplete = (function i() {
  const publicInterface = {
    init,
  };

  function setupAutoComplete({ input, inputId, types, city }) {
    const autocomplete = new google.maps.places.Autocomplete(input, {
      bounds: city.getBounds(),
      types,
    });

    // Specify the fields we will use, in order to avoid paying for data that we don't need.
    autocomplete.setFields(['address_components', 'formatted_address', 'geometry', 'place_id']);

    autocomplete.setComponentRestrictions({ country: [gMarket.countryCode.toLowerCase()] });
    autocomplete.addListener('place_changed', setAddress({ autocomplete, input, inputId, types }));
  }

  function setAddress({ autocomplete, input, inputId, types }) {
    return function() {
      const place = autocomplete.getPlace();

      /* Skip erroneous places caused by e.g. programmatic setting of the
       * autocomplete input field's value.
       */
      if (Object.keys(place).length === 1) return;

      if (!place.geometry) {
        setTimeout(() => {
          if (!$('.pac-container .pac-item').length) return;
          google.maps.event.trigger(input, 'focus', {});
          google.maps.event.trigger(input, 'keydown', {
            keyCode: 40, // arrow down
            stopPropagation: () => {},
            preventDefault: () => {},
          });
          google.maps.event.trigger(input, 'keydown', { keyCode: 13 }); // enter
        }, 100);
      }

      const address = formatGooglePlaceToAddress(place);

      address.raw_components = getAddressComponentsFromPlace(place.address_components);

      /* Hack allowing entering a street number for addresses lacking street numbers. */

      // Regexp safe street string.
      const streetRegexp = (address.street || '').replace(/[|\\{}()[\]^$+*?.-]/g, '\\$&');
      // Regexp for extracting the street number.
      let streetNoRegexp = `^${streetRegexp} `;
      if (gMarket.countryCode === 'GB') {
        streetNoRegexp = `^([^ ]+) *${streetRegexp}`;
      }
      // If the address object lacks a street number, retrieve it from the user's input.
      address.streetNo = address.streetNo || (input.value.match(streetNoRegexp) || [])[1];
      // Generate a new address name field by replacing any street not followed by a street number.
      const name = getAddressNameFromComponents(address);
      if (gMarket.countryCode === 'GB') {
        address.name = name.replace(new RegExp(streetRegexp), `${address.streetNo || ''} ${address.street || ''}`);
      } else {
        address.name = name.replace(new RegExp(`${streetRegexp}(?!\\s+\\d+)`), `${address.street || ''} ${address.streetNo || ''}`);
      }

      /* end of hack */

      // Buffer the value and trigger an event.
      input.setAttribute('data-tiptappAddress', JSON.stringify(address));
      const inputName = $(`form :input#${inputId}`).attr('name');
      if (inputName) {
        tiptapp.store.address.set(inputName, address);
        tiptapp.store.input.remove(inputName);
      }
      $(`form :input#${inputId}`).trigger($.Event('change', { tiptappAddress: address }));
      tiptapp.validation.validateAddress(inputId);

      if (inputId === 'from-address') {
        tiptapp.validation.validateEqualAddress(inputId, 'to-address');
      }

      if (!types || !types.includes('establishment')) {
        // Trying re-format to display without country and region.
        if (address.street && address.street.trim()) {
          address.displayName = `${address.street} `;
        }

        if (address.displayName && address.streetNo && address.streetNo.trim()) {
          address.displayName += address.streetNo;
        }

        if (address.displayName && input.value.trim() !== address.displayName.trim()) {
          input.value = address.displayName;
        }
      }
    };
  }
  function setupAddrEventHandler(inputId, onlyEstablishments) {
    const input = document.getElementById(inputId);
    if (!input) {
      return;
    }
    const stockholm = new google.maps.Circle({
      center: { lat: 59.324796, lng: 18.070586 },
      radius: 70000,
    });
    const london = new google.maps.Circle({
      center: { lat: 51.507528, lng: -0.127791 },
      radius: 70000,
    });
    const lisbon = new google.maps.Circle({
      center: { lat: 38.722252, lng: -9.139342 },
      radius: 70000,
    });
    const berlin = new google.maps.Circle({
      center: { lat: 52.5170365, lng: 13.3888599 },
      radius: 70000,
    });

    let city = stockholm;
    if (gMarket.countryCode === 'GB') {
      city = london;
    }
    if (gMarket.countryCode === 'PT') {
      city = lisbon;
    }
    if (gMarket.countryCode === 'DE') {
      city = berlin;
    }

    let types = ['address'];
    if (gMarket.countryCode === 'GB') {
      types = [];
    }
    if (onlyEstablishments) {
      types = ['establishment'];
    }

    setupAutoComplete({ input, types, city, inputId });

    // Select first autocomplete option upon field blur (e.g. at pressing Next button).
    google.maps.event.addDomListener(input, 'blur', () => {
      if ($('.pac-container:hover').length === 0) {
        google.maps.event.trigger(input, 'focus', {});
        google.maps.event.trigger(input, 'keydown', {
          keyCode: 40, // arrow down
          stopPropagation: () => {},
          preventDefault: () => {},
        });
        google.maps.event.trigger(input, 'keydown', { keyCode: 13 }); // enter
      }
    });
  }

  function init() {
    let onlyFromEstablishments = false;

    try {
      if (gVariant === 'store') onlyFromEstablishments = true;
    } catch (err) {
      onlyFromEstablishments = false;
    }

    setupAddrEventHandler('to-address');
    setupAddrEventHandler('from-address', onlyFromEstablishments);
    selectFirstOnEnter(document.getElementById('to-address'));
    selectFirstOnEnter(document.getElementById('from-address'));
  }

  /* Hack that allows pressing enter/ok in the address field to pick
   * the first autocomplete option and avoid any ugly error alerts.
   */
  function selectFirstOnEnter(input) {
    if (!input) {
      return;
    }
    // store the original event binding function
    const addEventListenerOrig = input.addEventListener ? input.addEventListener : input.attachEvent;
    // Simulate a 'down arrow' keypress on hitting 'return' when no pac suggestion is selected, and then trigger the original listener.
    const addEventListenerWrapper = function w(type, listener) {
      if (type === 'keydown') {
        const origListener = listener;
        // eslint-disable-next-line no-param-reassign
        listener = function l(event) {
          const suggestionSelected = $('.pac-item-selected').length > 0;
          if (event.which === 13 && !suggestionSelected) {
            const simulatedDownarrow = $.Event('keydown', { keyCode: 40, which: 40 });
            origListener.apply(input, [simulatedDownarrow]);
          }
          origListener.apply(input, [event]);
        };
      }
      addEventListenerOrig.apply(input, [type, listener]); // add the modified listener
    };

    if (input.addEventListener) {
      // eslint-disable-next-line no-param-reassign
      input.addEventListener = addEventListenerWrapper;
    } else if (input.attachEvent) {
      // eslint-disable-next-line no-param-reassign
      input.attachEvent = addEventListenerWrapper;
    }
  }

  return publicInterface;
})();
