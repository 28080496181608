// call this function with the google place result
const formatGooglePlaceToAddress = place => {
  let address = {};
  if (place.place_id) address.id = place.place_id;
  if (place.formatted_address) address.name = place.formatted_address;
  if (place.geometry) address.loc = place.geometry.location;
  if (place.address_components) {
    const components = getAddressComponentsFromPlace(place.address_components);
    address = { ...address, ...components };
  }
  // fallback incase google doesn't provide formatted_address
  if (!address.name) address.name = getAddressNameFromComponents(address);
  return address;
};

// additional help functions
const getAddressComponentsFromPlace = placeComponents => {
  const components = placeComponents;
  const address = {};
  let areaIsSet = false;

  if (!components || !Array.isArray(components)) return address;

  components.forEach(component => {
    if (!component.types || !Array.isArray(component.types)) return;
    let key = replacePlaceKeyWithAddressKey(component.types[0]);
    if (!key && component.types[1]) {
      key = replacePlaceKeyWithAddressKey(component.types[1]);
    }
    if (key === 'area' && areaIsSet) return;
    if (key === 'area') areaIsSet = true;

    if (!key) return;

    if (key === 'country') return (address[key] = component.short_name);
    const longName = component.long_name;
    if (longName) return (address[key] = longName);
    const shortName = component.short_name;
    if (shortName) return (address[key] = shortName);
  });

  return address;
};

const replacePlaceKeyWithAddressKey = key => {
  switch (key) {
    case 'country':
      return 'country';

    case 'route':
      return 'street';

    case 'street_number':
      return 'streetNo';

    case 'postal_code':
      return 'zip';

    case 'postal_town':
    case 'locality':
    case 'administrative_area_level_2':
    case 'administrative_area_level_1':
      return 'area';

    // case "sublocality_level_1":
    // case "sublocality_level_2":
    // case "sublocality_level_3":
    //   return "area";

    // case "administrative_area_level_1":
    //   return "county";

    default:
      return null;
  }
};

const getAddressNameFromComponents = address => {
  if (!address) return '';
  let streetArray = [address.street, address.streetNo];
  if (gMarket.countryCode === 'GB') {
    streetArray.reverse();
  }
  const street = streetArray.filter(ith => !!ith).join(' ');
  const area = [address.zip, address.area].filter(ith => !!ith).join(' ');
  return [street, area].filter(ith => !!ith).join(', ');
};
