window.tiptapp = window.tiptapp || {};

tiptapp.wasteCategories = (function init() {
  const publicInterface = {
    renderWasteCategories,
    renderWasteCategoryRules,
  };

  $('#waste-categories-form').submit(async event => {
    event.preventDefault();

    const hasErrors = tiptapp.validation.validateWasteCategories();
    if (hasErrors) {
      return;
    }

    const wasteCategoryIds = $('input.wasteCategory:checked')
      .map(function mapCategory() {
        return $(this).prop('value');
      })
      .get();

    tiptapp.store.ad.updateWasteSorted($('#wasteSorted').prop('checked'));
    tiptapp.store.ad.updateWasteCategoryIds(wasteCategoryIds);

    const data = {
      wasteCategoryIds,
      from: tiptapp.getAddress('from'),
    };

    try {
      const response = await $.ajax({
        url: '/api/waste-category-rules',
        type: 'PUT',
        headers: { ...tiptapp.main.getApiHeaders() },
        data: JSON.stringify(data),
        contentType: 'application/json',
      });

      const wasteCategoryRules = response.items || [];

      wasteCategoryRules.map(rule => ({ ...rule, wasteCategoryIds: rule.wasteCategoryIds.filter(id => wasteCategoryIds.includes(id)) }));

      tiptapp.store.wasteCategoryRules.set(wasteCategoryRules);

      tiptapp.wasteCategories.renderWasteCategoryRules();

      if (wasteCategoryRules.length > 0) {
        tiptapp.setScreen('waste-category-rules-state');
      } else {
        const phone = tiptapp.store.phone.get();
        $('#verify-phone-no').text(phone);
        const url = $('#waste-categories-form').attr('action');
        tiptapp.getLoginCode(url, phone);
      }

      tiptapp.analytics.trackEvent('web_waste_categories_continue', {
        wasteCategories: wasteCategoryIds,
      });
    } catch (error) {
      const response = error.responseJSON || {};
      console.error(error, response);
    }
  });

  $('#waste-category-rules-form').submit(function onSubmit(event) {
    event.preventDefault();

    if (!tiptapp.validation.validateWasteCategoryRules()) {
      const formData = $(this).serializeArray();

      const wasteCategoryRules = $('.waste-category-rule')
        .map(function mapWasteRule() {
          const option = (formData.find(field => this.id.replace('wcr_', 'wco_') === field.name) || {}).value;
          if (option) {
            return { id: this.id.replace('wcr_', ''), option };
          }

          return { id: this.id.replace('wcr_', '') };
        })
        .get();

      tiptapp.store.ad.updateWasteCategoryRules(wasteCategoryRules);

      const phone = tiptapp.store.phone.get();
      $('#verify-phone-no').text(phone);
      const url = $('#waste-category-rules-form').attr('action');
      tiptapp.getLoginCode(url, phone);
    }
  });

  function renderWasteCategories() {
    const { wasteCategoryIds } = tiptapp.store.ad.get();
    (wasteCategoryIds || []).forEach(wasteCategoryId => {
      $(`#wc_${wasteCategoryId}`).prop('checked', true);
    });
    tiptapp.wasteCategoriesData
      .flatMap(({ items }) => items)
      .filter(wasteCategory => wasteCategory.info && wasteCategory.info.body)
      .forEach(wasteCategory => {
        $(`#wci_${wasteCategory.id}`).on('click', event => {
          event.preventDefault();
          alert(window.i18next.t(wasteCategory.info.body, { ns: 'applanga' }));
        });
      });
  }

  function renderWasteCategoryRules() {
    const wasteCategoryRules = tiptapp.store.wasteCategoryRules.get();
    const { wasteCategoryRules: wasteCategoryRuleOptions } = tiptapp.store.ad.get();

    $('.waste-category-rule', $('#waste-category-rules-container')).remove();

    (wasteCategoryRules || []).forEach(rule => {
      const elm = $('#waste-category-rule-template')
        .clone()
        .attr('id', `wcr_${rule.id}`)
        .addClass('waste-category-rule');

      const flatWasteCategories = tiptapp.wasteCategoriesData
        .flatMap(({ items }) => items.map(item => item))
        .reduce((acc, wasteCategory) => ({ ...acc, [wasteCategory.id]: wasteCategory }), {});

      rule.wasteCategoryIds
        .flatMap(wasteCategoryId => flatWasteCategories[wasteCategoryId] || [])
        .forEach(wasteCategory => {
          const iconName = wasteCategory.iconSelected || `${wasteCategory.icon}_selected`;
          $(`<img src="/img/waste-categories/${iconName}.png" />`).appendTo($('.waste-category-rule-icons', elm));
        });

      $('.waste-category-rule-title', elm).text(window.i18next.t(rule.title, { ns: 'applanga' }));
      $('.waste-category-rule-description', elm).html(window.i18next.t(rule.description, { ns: 'applanga' }));
      $('.waste-category-rule-body', elm).html(window.i18next.t(rule.body, { ns: 'applanga' }));

      if (!rule.options || !rule.options.length) {
        $('.waste-category-rule-options-header', elm).css('display', 'none');
        $('.waste-category-rule-options', elm).css('display', 'none');
      }

      rule.options.forEach(option => {
        const selectedOption = ((wasteCategoryRuleOptions || []).find(({ id }) => rule.id === id) || {}).option;
        $(
          `<label class="waste-category-option"><input type="radio" name="wco_${rule.id}" value="${option.id}"${
            option.id === selectedOption ? ' checked="checked"' : ''
          } /><span>${window.i18next.t(option.title, {
            ns: 'applanga',
          })}</span></label>`
        )
          .appendTo($('.waste-category-rule-options', elm))
          .on('change', function onChange() {
            if (option.showsRuleBody) {
              $('.waste-category-rule-body', elm).show();
              $('.waste-category-rule-terms', elm).show();
            } else {
              $('.waste-category-rule-body', elm).hide();
              $('.waste-category-rule-terms', elm).hide();
            }
          });
      });

      if (rule.options.length === 0) {
        $('.waste-category-rule-terms', elm).show();
      }

      $('.waste-category-rule-options-error', elm).attr('id', `wcr_${rule.id}-error`);

      const termsCheckElm = $('.waste-category-rule-terms .termsCheckBox', elm);
      termsCheckElm.attr('id', `wcrt_${rule.id}`);
      termsCheckElm.on('change', () => {
        // tiptapp.validation.validateWasteCategoryRules();
      });

      $('.waste-category-rule-terms label', elm)
        .attr('for', `wcrt_${rule.id}`)
        .attr('id', `wcrt_${rule.id}-label`);

      $('.waste-category-rule-error', elm).attr('id', `wcr_${rule.id}-error`);
      $('.waste-category-rule-terms-error', elm).attr('id', `wcrt_${rule.id}-label-error`);

      elm.appendTo($('#waste-category-rules-container')).css('display', 'block');
    });
  }

  return publicInterface;
})();
