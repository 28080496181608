var box = document.getElementById('slider-box');
var car1 = document.getElementById('small-car');
var car2 = document.getElementById('medium-car');
var car3 = document.getElementById('large-car');

function onSliderDrag(value) {
  box.style.transform = `scale(${value / 100},${value / 100})`;
  if (value >= 25 && value <= 38) {
    car1.style.display = 'block';
    car2.style.display = 'none';
    car3.style.display = 'none';
  }
  if (value >= 38 && value <= 66) {
    car1.style.display = 'none';
    car2.style.display = 'block';
    car3.style.display = 'none';
  }
  if (value >= 60 && value <= 75) {
    car1.style.display = 'none';
    car2.style.display = 'none';
    car3.style.display = 'block';
  }
}

function onSliderRelease(value) {}
