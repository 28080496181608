/**
 * Image upload functionality.
 */

window.tiptapp = window.tiptapp || {};

tiptapp.imageupload = (function init() {
  const publicInterface = {
    setImageEvents,
    loadUploadedImages,
    removeImage,
    onImagesChange,
    getImagesCount,
  };

  const images = [];
  const onImagesChangeListeners = [];

  function createNewImageElm() {
    const firstElm = $('#images-container > .image-input').first();
    const lastN = firstElm ? +firstElm.attr('id').replace('image-input-', '') : 0;
    const newElm = $('#image-template').clone(false);

    newElm.find('*').each((_i, e) => {
      if (e.id) {
        $(e).attr('id', e.id.replace('-N', `-${lastN + 1}`));
      }
      if ($(e).attr('for')) {
        $(e).attr(
          'for',
          $(e)
            .attr('for')
            .replace('-N', `-${lastN + 1}`)
        );
      }
    });

    $('#images-container').prepend(newElm.contents());
    setImageEvents($(`#image-input-${lastN + 1}`));

    return lastN + 1;
  }

  function loadUploadedImages(limit) {
    $('#images-container .image-input[value].has-image').each(function i() {
      images.push({ imgUrl: $(this).attr('value') });
    });

    const loadImage = (i, stop) => {
      const storageKey = `adImage${i}`;
      const imgUrl = tiptapp.store.getItem(storageKey);
      if (!imgUrl && (!stop || i >= stop)) {
        if (images.length) {
          imagesChanged();
        }
        return false;
      }

      if (imgUrl) {
        const n = createNewImageElm();
        $(`#image-input-${n - 1}`).addClass('has-image');
        $(`#image-input-${n - 1}`).data('storage-key', storageKey);
        $(`#ad-img-${n - 1}`).css('background-image', `url(${imgUrl})`);
        $(`#ad-img-overlay-${n - 1}`).removeClass('visible');
        images.push({ storageKey, imgUrl });
      }

      return loadImage(i + 1, stop);
    };

    loadImage(0, limit);
  }

  function setImageEvents($element) {
    // eslint-disable-next-line prettier/prettier
    $element.each(function f() {
      if (!this.id) return;

      const i = +this.id.replace('image-input-', '');
      const container = $(`#ad-img-container-${i}`);

      $(this)
        .click(
          e =>
            !(
              !!$(e.target).hasClass('has-image') &&
              // removeImage(e.target) &&
              !!e.preventDefault()
            )
        )
        .change(handleImageInputChange);

      $(`.ad-img-clear`, container).click(() => removeImage(i, $(this).data('storage-key')));
    });
  }

  function storeAndPresentImage(dataUrl) {
    return new Promise((resolve, reject) => {
      // Create an element for the next image.
      const newElmIndex = createNewImageElm();
      const i = newElmIndex - 1;

      try {
        const storageKey = `adImage${i}`;
        tiptapp.store.setItem(storageKey, dataUrl, { raw: true });

        $(`#ad-img-overlay-${newElmIndex} .ad-img-spinner`).addClass('visible');

        $(`#image-input-${i}`)
          .data('storage-key', storageKey)
          .addClass('has-image');
        $(`#ad-img-${i}`).css('background-image', `url(${dataUrl})`);
        $(`#ad-img-overlay-${i}`).removeClass('visible');

        resolve({ storageKey, dataUrl });
      } catch (error) {
        removeImage(newElmIndex);
        reject();
      }
    });
  }

  function handleImageInputChange(event) {
    const buttonIndex = +event.target.id.replace('image-input-', '');
    const files = event.target.files || [];

    $(`#ad-img-overlay-${buttonIndex} .ad-img-spinner`).addClass('visible');

    const promises = Array.from(files).map(file =>
      getImageDataURL(file)
        .then(dataUrl => storeAndPresentImage(dataUrl))
        .catch(error => {
          return Promise.resolve(new Error(error));
        })
    );

    Promise.all(promises)
      .then(results => {
        if (results.find(result => result instanceof Error)) {
          tiptapp.blocket.toggleErrorModal(true);
        } else {
          results.forEach(result => images.push({ storageKey: result.storageKey, imgUrl: result.dataUrl }));
        }
      })
      .catch(() => {
        tiptapp.blocket.toggleErrorModal(true);
      })
      .finally(() => {
        $(`#images-container .ad-img-spinner`).removeClass('visible');
        imagesChanged();
      });
  }

  function resizeAndRotate(img, orientation) {
    const canvas = document.createElement('canvas');

    let { width, height } = img;
    const MAX_WIDTH = 1242;
    const MAX_HEIGHT = 1242;

    if (width > height && width > MAX_WIDTH) {
      height *= MAX_WIDTH / width;
      width = MAX_WIDTH;
    } else if (height > MAX_HEIGHT) {
      width *= MAX_HEIGHT / height;
      height = MAX_HEIGHT;
    }

    canvas.width = width;
    canvas.height = height;

    // set proper canvas dimensions before transform & export
    if (orientation > 4 && orientation < 9) {
      canvas.width = height;
      canvas.height = width;
    }

    const ctx = canvas.getContext('2d');

    // transform context before drawing image
    switch (orientation) {
      case 2:
        ctx.transform(-1, 0, 0, 1, width, 0);
        break;
      case 3:
        ctx.transform(-1, 0, 0, -1, width, height);
        break;
      case 4:
        ctx.transform(1, 0, 0, -1, 0, height);
        break;
      case 5:
        ctx.transform(0, 1, 1, 0, 0, 0);
        break;
      case 6:
        ctx.transform(0, 1, -1, 0, height, 0);
        break;
      case 7:
        ctx.transform(0, -1, -1, 0, height, width);
        break;
      case 8:
        ctx.transform(0, -1, 1, 0, 0, width);
        break;
      default:
        break;
    }

    ctx.drawImage(img, 0, 0, width, height);

    const dataUrl = canvas.toDataURL('image/jpeg');

    return dataUrl;
  }

  function getImageDataURL(file) {
    if (!file || !(file instanceof File) || !(file instanceof Blob)) {
      return Promise.reject(new Error('Invalid File specified.'));
    }

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    return eventToPromise(reader, 'load').then(e => {
      const imgData = e.target.result;
      const imgDataBase64 = arrayBufferToBase64(imgData);

      const img = new Image();
      img.src = `data:image/jpeg;base64,${imgDataBase64}`;

      return eventToPromise(img, 'load').then(() => {
        const orientation = getOrientation(imgData);
        return resizeAndRotate(img, orientation);
      });
    });
  }

  function removeImage(id, storageKey) {
    try {
      if (storageKey) {
        tiptapp.store.removeItem(storageKey);
        const imgIndex = images.findIndex(elm => elm.storageKey === storageKey);
        if (imgIndex !== -1) {
          images.splice(imgIndex, 1);
        }
      } else {
        const extImgUrl = $(`#image-input-${id}`).attr('value');
        const extImgIndex = gExternalAd.images.findIndex(image => new RegExp(escapeRegExp(extImgUrl)).test(image));
        const removedUrls = tiptapp.store.getItem('removedExtAdImgs') || [];
        if (extImgIndex !== -1) {
          if (removedUrls.indexOf(gExternalAd.images[extImgIndex]) === -1) {
            removedUrls.push(gExternalAd.images[extImgIndex]);
            tiptapp.store.setItem('removedExtAdImgs', removedUrls);
          }
          gExternalAd.images.splice(extImgIndex, 1);

          const imgIndex = images.findIndex(elm => elm.imgUrl === extImgUrl);
          if (imgIndex !== -1) {
            images.splice(imgIndex, 1);
          }
        } else {
          return false;
        }
      }
    } catch (error) {
      return false;
    }

    $(`#image-input-${id}`).remove();
    $(`#ad-img-container-${id}`).remove();

    if ($('#images-container .image-input').length === 1) {
      createNewImageElm();
    }

    imagesChanged();

    return true;
  }

  function getOrientation(imageData) {
    const view = new DataView(imageData);

    if (view.getUint16(0, false) !== 0xffd8) {
      return -2;
    }

    const length = view.byteLength;
    let offset = 2;
    while (offset < length) {
      if (view.getUint16(offset + 2, false) <= 8) return -1;
      const marker = view.getUint16(offset, false);
      offset += 2;
      if (marker === 0xffe1) {
        offset += 2;
        if (view.getUint32(offset, false) !== 0x45786966) {
          return -1;
        }

        offset += 6;
        const little = view.getUint16(offset, false) === 0x4949;
        offset += view.getUint32(offset + 4, little);
        const tags = view.getUint16(offset, little);
        offset += 2;
        for (let i = 0; i < tags; i += 1) {
          if (view.getUint16(offset + i * 12, little) === 0x0112) {
            return view.getUint16(offset + i * 12 + 8, little);
          }
        }
        // eslint-disable-next-line no-bitwise
      } else if ((marker & 0xff00) !== 0xff00) {
        break;
      } else {
        offset += view.getUint16(offset, false);
      }
    }
    return -1;
  }

  function arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i += 1) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  function eventToPromise(object, eventName) {
    return new Promise((resolve, reject) => {
      const successHandler = e => {
        object.removeEventListener(eventName, successHandler);
        resolve(e);
      };
      const errorHandler = e => {
        object.removeEventListener('error', errorHandler);
        reject(e);
      };
      object.addEventListener(eventName, successHandler);
      object.addEventListener('error', errorHandler);
    });
  }

  function onImagesChange(listener) {
    onImagesChangeListeners.push(listener);
  }

  function imagesChanged() {
    onImagesChangeListeners.forEach(func => func(images.map(img => img.imgUrl)));
  }

  function getImagesCount() {
    return images.length;
  }

  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  return publicInterface;
})();
