// event_web_session_start blocket_ad_id, blocket_kategori, blocket_ad_id, tiptapp_id
// event_web_create_ad_form_continue
// event_web_verify_phone
// event_web_create_ad
// event_web_tap_dynamic_link

window.tiptapp = window.tiptapp || {};

tiptapp.analytics = (() => {
  const publicInterface = {
    trackEvent,
    setUser,
    getUserId,
  };

  function trackEvent(eventName, eventData, skipFirebase = false) {
    const tempUserId = tiptapp.store.getItem('analytics:tempUserId');
    const httpReferrer = tiptapp.store.getItem('analytics:referrer');
    const ad = tiptapp.store.ad.get();
    const externalAd = tiptapp.store.getItem('externalAd');
    const payRecommendation = $('#recommended-price').text() || null;
    const payRecommendationAlgo = $('#recommended-price-algo').val() || null;

    const data = { ad: { ...ad, payRecommendation, payRecommendationAlgo }, ...eventData, tempUserId, httpReferrer };

    let externalAdPrefix;
    let eventPrefix;

    try {
      externalAdPrefix = `${gReferrerName}Ad` || 'unknownAd';
      eventPrefix = gReferrerName || 'unknown';
      // TODO: consider moving to config.
      if (eventPrefix === 'fbmarketplace') {
        eventPrefix = 'fbm';
      } else if (eventPrefix === 'lyxloppis') {
        eventPrefix = 'lxlo';
      } else if (eventPrefix === 'boappa') {
        eventPrefix = 'boappa';
      } else if (gVariant === 'tapaway' || gVariant === 'fb') {
        eventPrefix = 'tapaway';
      } else if (gVariant === 'move') {
        eventPrefix = 'move';
      } else if (gVariant === 'store') {
        eventPrefix = 'store';
      } else if (gVariant === 'coop') {
        eventPrefix = 'coop';
        externalAdPrefix = 'coop';
      }
    } catch (err) {
      externalAdPrefix = 'unknownAd';
      eventPrefix = 'unknown';
    }

    data[externalAdPrefix] = externalAd;

    if (tiptapp.isProd && !skipFirebase) {
      const filteredData = tiptapp.util.filterOutSensitiveData(data);
      const dataFlattened = tiptapp.util.flatten(filteredData, '_');

      Object.keys(dataFlattened).forEach(key => {
        if (typeof dataFlattened[key] === 'string') {
          dataFlattened[key] = dataFlattened[key].substring(0, 100);
        }
      });

      try {
        firebase.analytics().logEvent(`${eventPrefix}_${eventName}`, dataFlattened);
      } catch (error) {
        console.warn(`Failed to send event ${eventPrefix}_${eventName} to Firebase: `, error);
      }
    }

    return getUserId().then(({ userId, anonymousId }) =>
      $.ajax({
        url: '/api/track/event',
        type: 'POST',
        data: JSON.stringify({
          userId,
          anonymousId,
          eventName: `event_${eventPrefix}_${eventName}`,
          data,
        }),
        contentType: 'application/json',
      })
    );
  }

  function setUser(userId) {
    let previousId = tiptapp.store.getItem('analytics:userId');
    if (!previousId) {
      previousId = tiptapp.store.getItem('analytics:tempUserId');
    }

    if (!userId || userId.length === 0) {
      // console.warn('Failed to set analytics user: userId is invalid.');
      return Promise.resolve(0);
    }

    /* Store synchronously to make sure userId used by any subsequent trackEvent calls.
     * It doesn't matter whether the server call succeeds.
     */
    tiptapp.store.setItem('analytics:userId', userId);

    return $.ajax({
      url: '/api/track/user',
      type: 'POST',
      data: JSON.stringify({ userId, previousId }),
      contentType: 'application/json',
    })
      .fail(error => {
        // console.log('Track Error\n', data);
      })
      .done(response => {
        tiptapp.store.setItem('analytics:userId', userId);
        // console.log('[TRACKED setUser]', response);
      });
  }

  function getUserId() {
    const userId = tiptapp.store.getItem('analytics:userId');
    const tempUserId = tiptapp.store.getItem('analytics:tempUserId');

    if (userId) return Promise.resolve({ userId, anonymousId: tempUserId });

    if (tempUserId) {
      // tiptapp.store.setItem('analytics:userId', anonymousId);
      return Promise.resolve({ anonymousId: tempUserId });
    }

    return $.ajax({
      url: '/api/track/user',
      type: 'GET',
      contentType: 'application/json',
    })
      .fail(error => {
        // console.log('Track Error\n', data);
      })
      .done(anonymousId => {
        tiptapp.store.setItem('analytics:tempUserId', anonymousId);
        // tiptapp.store.setItem('analytics:userId', anonymousId);
        // console.log('[TRACKED getUserId]', id);

        return { anonymousId };
      });
  }

  return publicInterface;
})();
