/**
 * Leanplum interface
 */

window.tiptapp = window.tiptapp || {};

tiptapp.leanplum = (function init() {
  const publicInterface = {
    setup,
    setTrafficSource,
    getVars,
  };

  function setup(cb) {
    const isDevelopmentMode = tiptapp.leanplumConfig.isDev;

    if (isDevelopmentMode) {
      Leanplum.setAppIdForDevelopmentMode(tiptapp.leanplumConfig.appId, tiptapp.leanplumConfig.clientKeyDev);
    } else {
      Leanplum.setAppIdForProductionMode(tiptapp.leanplumConfig.appId, tiptapp.leanplumConfig.clientKey);
    }

    Leanplum.start(success => {
      if (success && typeof cb === 'function') {
        cb();
      } else if (!success) {
        console.warn('Failed to start Leanplum.');
      }
    });
  }

  function setTrafficSource(userId, ad, referrerName) {

    if (!userId || !ad || !referrerName) {
      return;
    }

    const source = createSource(ad, referrerName);
    return Leanplum.setUserAttributes(userId, { trafficSource: source });
  }

  return publicInterface;


  function createSource(ad, referrerName) {
    let source = {};

    switch (referrerName) {
      case 'blocket':
        source = {
          adId: String(ad._id),
          reference: ad.referrer.reference,
          publisherId: 'TTB1',
          publisherName: 'Blocket',
          publisherSubPublisher: ad.from.area || ad.from.country || ad.country,
          publisherSubSite: 'Blocket + Tiptapp',
          publisherSubCampaign: referrerName,
          publisherSubAdGroup: '',
          publisherSubAd: ad.title
        };
        break;

      case 'budi':
        source = {
          adId: String(ad._id),
          reference: ad.referrer.reference,
          publisherId: 'TTBUDI1',
          publisherName: 'Budi',
          publisherSubPublisher: ad.from.area || ad.from.country || ad.country,
          publisherSubSite: 'Budi + Tiptapp',
          publisherSubCampaign: referrerName,
          publisherSubAdGroup: '',
          publisherSubAd: ad.title,
        };
        break;

      case 'olx':
      case 'olxweb':
        source = {
          adId: String(ad._id),
          reference: ad.referrer.reference,
          publisherId: 'TTOLX1',
          publisherName: 'OLX',
          publisherSubPublisher: ad.from.area || ad.from.country || ad.country,
          publisherSubSite: 'OLX + Tiptapp',
          publisherSubCampaign: referrerName,
          publisherSubAdGroup: '',
          publisherSubAd: ad.title
        };
        break;

      case 'coop-express':
        source = {
          adId: String(ad._id),
          reference: ad.referrer.reference,
          publisherId: 'TTCOOP1',
          publisherName: 'Coop Express',
          publisherSubPublisher:
            ad.hasOwnProperty('from.area') || ad.hasOwnProperty('from.country') || ad.hasOwnProperty('country'),
          publisherSubSite: 'Coop + Tiptapp',
          publisherSubCampaign: referrerName,
          publisherSubAdGroup: '',
          publisherSubAd: ad.hasOwnProperty('title')
        };
        break;
      default:
        break;
    }

    return source;
  }

  function getVars(name) {
    return Leanplum.getVariable(name);
  }
})();
