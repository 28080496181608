window.tiptapp = window.tiptapp || {};
const filters = [
  // Tiptapp ad data filter.
  {
    match: data => _.get(data, 'ad.from') != null,
    filter: data =>
      _.omit(data, [
        'ad.from.contactInfo',
        'ad.from.name',
        'ad.from.street',
        'ad.from.streetNo',
        'ad.to.contactInfo',
        'ad.to.name',
        'ad.to.street',
        'ad.to.streetNo',
      ]),
  },
  // Blocket ad data filter.
  {
    match: data => _.get(data, 'blocketAd') != null,
    filter: data =>
      _.omit(data, [
        'blocketAd.body',
        'blocketAd.company_orgnum',
        'blocketAd.has_account',
        'blocketAd.hide_address',
        'blocketAd.images',
        'blocketAd.phone',
        'blocketAd.phonegifs',
        'blocketAd.safety_tips',
        'blocketAd.state_id',
        'blocketAd.use_messagecenter',
      ]),
  },
  // FB Marketplace ad data filter.
  {
    match: data => _.get(data, 'fbmarketplaceAd') != null,
    filter: data => _.omit(data, ['fbmarketplaceAd.images']),
  },
];

tiptapp.util = (() => {
  const publicInterface = {
    flatten,
    filterOutSensitiveData,
    unflatten,
  };

  function filterOutSensitiveData(data) {
    return filters.filter(filter => filter.match(data)).reduce((result, filter) => filter.filter(result), data);
  }

  function flatten(object, separator = '.') {
    return Object.assign(
      {},
      ...(function _flatten(child, path = []) {
        return [].concat(
          ...Object.keys(child).map(key =>
            typeof child[key] === 'object' && child[key] != null
              ? _flatten(child[key], path.concat([key]))
              : { [path.concat([key]).join(separator)]: child[key] }
          )
        );
      })(object)
    );
  }

  function unflatten(myData, separator = '.') {
    const result = {};
    Object.keys(myData).forEach(i => {
      const keys = i.split(separator);
      keys.reduce((r, e, j) => r[e] || (r[e] = Number.isNaN(Number(keys[j + 1])) ? (keys.length - 1 === j ? myData[i] : {}) : []), result);
    });
    return result;
  }

  return publicInterface;
})();
