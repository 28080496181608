/* eslint-disable */
function pingHotjarWithStateChange(state) {
  // If the Hotjar plugin has been included, trigger a state change.
  if (typeof hj === 'function') {
    // eslint-disable-next-line no-undef
    hj('stateChange', state || 'unknown');
  }
}
window.tiptapp = window.tiptapp || {};
tiptapp.setScreen = function(state) {
  const ad = tiptapp.store.ad.get();
  const toPhone = ad && ad.to && ad.to.contactInfo ? ad.to.contactInfo.phone : '';
  const toPhoneNoCountryCode =
    gMarket.diallingCode && toPhone.startsWith(gMarket.diallingCode) ? toPhone.substring(gMarket.diallingCode.length) : toPhone;

  const previousState = tiptapp.store.state.get();

  state = state || 'begin-state';
  tiptapp.store.state.set(state);

  if (history && typeof history.pushState === 'function') {
    history.pushState({ page: state }, state, `#${state}`);
  }

  $([document.documentElement, document.body]).animate({ scrollTop: 0 }, 500);

  pingHotjarWithStateChange(state);

    // screens
    let adCreateScreen = $('#ad-create'),
        insuranceScreen = $('#insurance'),
        wasteCategoriesScreen = $('#waste-categories'),
        wasteCategoryRulesScreen = $('#waste-category-rules'),
        loginScreen = $('#login'),
        resendCodeScreen = $('#resend-code'),
        loginSuccessScreen = $('.login-success'),
        loadingState = $('#loading-state');

    switch(state) {
        case 'begin-state':
            if (window.grecaptcha && typeof window.grecaptcha.reset === 'function' && previousState !== 'begin-state') {
              window.grecaptcha.reset(tiptapp.grecaptchaId);
            }
            adCreateScreen.css('display', 'block');
            insuranceScreen.css('display', 'none');
            wasteCategoriesScreen.css('display', 'none');
            wasteCategoryRulesScreen.css('display', 'none');
            loginScreen.css('display', 'none');
            resendCodeScreen.css('display', 'none');
            loginSuccessScreen.css('display', 'none');
            loadingState.css('display', 'none');
            tiptapp.views.loginSuccess.hide();
            break;
        case 'insurance-state':
            adCreateScreen.css('display', 'none');
            insuranceScreen.css('display', 'block');
            wasteCategoriesScreen.css('display', 'none');
            wasteCategoryRulesScreen.css('display', 'none');
            loginScreen.css('display', 'none');
            resendCodeScreen.css('display', 'none');
            loginSuccessScreen.css('display', 'none');
            loadingState.css('display', 'none');
            tiptapp.views.loginSuccess.hide();
            break;
        case 'waste-categories-state':
          adCreateScreen.css('display', 'none');
          insuranceScreen.css('display', 'none');
          wasteCategoriesScreen.css('display', 'block');
          wasteCategoryRulesScreen.css('display', 'none');
          loginScreen.css('display', 'none');
          resendCodeScreen.css('display', 'none');
          loginSuccessScreen.css('display', 'none');
          loadingState.css('display', 'none');
          tiptapp.views.loginSuccess.hide();
          break;
        case 'waste-category-rules-state':
          adCreateScreen.css('display', 'none');
          wasteCategoriesScreen.css('display', 'none');
          wasteCategoryRulesScreen.css('display', 'block');
          loginScreen.css('display', 'none');
          resendCodeScreen.css('display', 'none');
          loginSuccessScreen.css('display', 'none');
          loadingState.css('display', 'none');
          tiptapp.views.loginSuccess.hide();
          break;
        case 'login-state':
            adCreateScreen.css('display', 'none');
            insuranceScreen.css('display', 'none');
            wasteCategoriesScreen.css('display', 'none');
            wasteCategoryRulesScreen.css('display', 'none');
            resendCodeScreen.css('display', 'none');
            loginSuccessScreen.css('display', 'none');
            loadingState.css('display', 'none');
            $('#verify-phone-no').text(toPhone);
            loginScreen.css('display', 'block');
            $('#login-code').val('').focus();
            tiptapp.views.loginSuccess.hide();
            break;
        case 'resend-code-state':
            if (window.grecaptcha) {
              grecaptcha.reset(tiptapp.grecaptchaResendId);
            }
            adCreateScreen.css('display', 'none');
            insuranceScreen.css('display', 'none');
            wasteCategoriesScreen.css('display', 'none');
            wasteCategoryRulesScreen.css('display', 'none');
            loginScreen.css('display', 'none');
            loginSuccessScreen.css('display', 'none');
            loadingState.css('display', 'none');
            $('#resend-phone').val(toPhoneNoCountryCode).focus();
            resendCodeScreen.css('display', 'block');
            tiptapp.views.loginSuccess.hide();
            break;
        case 'final-state':
            adCreateScreen.css('display', 'none');
            insuranceScreen.css('display', 'none');
            wasteCategoriesScreen.css('display', 'none');
            wasteCategoryRulesScreen.css('display', 'none');
            loginScreen.css('display', 'none');
            resendCodeScreen.css('display', 'none');
            loadingState.css('display', 'none');
            tiptapp.views.loginSuccess.display();
            break;
        case 'loading-state':
            adCreateScreen.css('display', 'none');
            insuranceScreen.css('display', 'none');
            wasteCategoriesScreen.css('display', 'none');
            wasteCategoryRulesScreen.css('display', 'none');
            loginScreen.css('display', 'none');
            resendCodeScreen.css('display', 'none');
            loadingState.css('display', 'block');
            break;
        default:
            adCreateScreen.css('display', 'block');
            tiptapp.views.loginSuccess.hide();
            break;
    }
}
