window.tiptapp = window.tiptapp || {};

tiptapp.blocket = (function i() {
  const publicInterface = {
    init,
    toggleErrorModal,
    getInsuranceEmail,
    getSelectedPriceBox,
    getSelectedInsurance,
    updateSelectedPriceBoxDescription,
    pickPricebox,
    pickInsurancePremium,
    resetInput,
    getOwnerFee,
  };

  let elements = [];
  let elementsMap = {};
  let priceBoxDescElement;
  let rekPriceTop;
  const ownerFee = {
    blocket: {
      SE: {
        enabled: true,
        fee: 39,
      },
    },
    budi: {
      SE: {
        enabled: true,
        fee: 19,
      },
    },
    move: {
      SE: {
        enabled: true,
        fee: 39,
      },
    },
    tapaway: {
      SE: {
        enabled: true,
        fee: 39,
      },
    },
    olx: {
      PT: {
        enabled: false,
        fee: 2,
      },
    },
  };

  function init({ tsConfig: { openDays, slotLengthHr, startHr, endHr, endHrPerDay, minSlotFracRemaining } }) {
    if ($('#slot-container-today').length || $('#slot-container-tomorrow').length) {
      // create both today's and tomorrow's time-slots
      // Todo: need to consider the scenario if the user takes too long to create ad and the oldest time-slots expires
      const startDate = new Date();
      const firstDaySlotsCreated = createTimeSlots({
        startDate,
        day: 'today',
        slotLengthHr,
        startHr,
        endHr,
        endHrPerDay,
        minSlotFracRemaining,
        openDays,
      });
      // if (slotsCreated === 0) {
      //   startDate.setDate(startDate.getDate() + 1);
      //   startDate.setHours(0, 0, 0, 0);
      //   slotsCreated = createTimeSlots({ startDate, day: 'today', slotLengthHr, startHr, endHr, minSlotFracRemaining, openDays });
      // }
      const secondDaySlotsCreated = createTimeSlots({
        startDate,
        day: 'tomorrow',
        slotLengthHr,
        startHr,
        endHr,
        endHrPerDay,
        minSlotFracRemaining: 1,
        openDays,
      });

      if (firstDaySlotsCreated === 0) {
        $('#tomorrow').addClass('selected-day');
        $('#tomorrow').css('border-top-left-radius', '4px');
        $('#tomorrow').css('border-bottom-left-radius', '4px');

        $('#today').hide();

        // $(document).ready(() => {
        tiptapp.store.timeSlotDay.set('tomorrow');
        // });
      }

      if (secondDaySlotsCreated === 0) {
        $('#today').addClass('selected-day');
        $('#today').css('border-top-right-radius', '4px');
        $('#today').css('border-bottom-right-radius', '4px');

        $('#tomorrow').hide();

        tiptapp.store.timeSlotDay.set('today');
      }

      if (firstDaySlotsCreated === 0 || secondDaySlotsCreated === 0) {
        tiptapp.main.reloadFormUsingStore(tiptapp.form.timeSlots.elementNameRegex);
      }

      if (firstDaySlotsCreated + secondDaySlotsCreated === 0) {
        $('#min-time-slot-text').hide();
        $('.delivery-note').hide();
        $('#no-slots-available').show();
      }
    }

    $('#ad-extra').click(function onClick() {
      $('#ad-extra').css('height', '100%');
      $('#resend-code').css('display', 'block');
    });

    $('#to-ad-extra').click(function onClick() {
      // toggle extra info
      $('#to-extra').slideToggle('medium');

      // toggle extra info icon
      $(this)
        .find('img')
        .toggle();
    });

    $('#from-ad-extra').click(function onClick() {
      // toggle extra info
      $('#from-extra').slideToggle('medium');

      // toggle extra info icon
      $(this)
        .find('img')
        .toggle();
    });

    // required for login screen
    // shows the phone input if user needs to resend the code to a new phone number
    $('#change-phone').click(function onClick() {
      $('#login').css('display', 'none');
      $('#resend-code').css('display', 'block');
    });

    // show today's time slot
    $('#today').click(function onClick() {
      const $today = $('#today');
      const $tomorrow = $('#tomorrow');

      $('#slot-container-tomorrow').hide();
      $('#slot-container-today').show();

      $today.addClass('selected-day');
      $tomorrow.removeClass('selected-day');

      tiptapp.store.timeSlotDay.set('today');
      tiptapp.main.reloadFormUsingStore(tiptapp.form.timeSlots.elementNameRegex);
    });

    // show tomorrow's time slot
    $('#tomorrow').click(function onClick() {
      const $today = $('#today');
      const $tomorrow = $('#tomorrow');

      $('#slot-container-today').hide();
      $('#slot-container-tomorrow').show();

      $tomorrow.addClass('selected-day');
      $today.removeClass('selected-day');

      tiptapp.store.timeSlotDay.set('tomorrow');
      tiptapp.main.reloadFormUsingStore(tiptapp.form.timeSlots.elementNameRegex);
    });

    // hide tool tip when clicked anywhere except the why icon
    $(document).click(function onClick(event) {
      if ($(event.target).attr('class') !== 'icon-why') {
        $('.tool-tip').css('display', 'none');
        $('.arrow-down').css('display', 'none');
      }
    });

    // toggle tool tip when clicked on the why icon
    $('.icon-why').click(function onClick() {
      $('.tool-tip').toggle();
      $('.arrow-down').toggle();
    });

    $('#cookie-consent-bar').on('hide', () => {
      $('.sticky-top').css('top', '0');
    });

    // stick on top when elemnet hits on top
    $(window).scroll(function onScroll() {
      if (!rekPriceTop && $('.rek-price:not(.static)').length) {
        rekPriceTop = $('.rek-price:not(.static)').offset().top;
      }
      if ($(window).scrollTop() > rekPriceTop) {
        $('.rek-price:not(.static)').addClass('sticky-top');
        if (document.getElementById('cookie-consent-bar')) {
          const cookieBarHeight = document.getElementById('cookie-consent-bar').getBoundingClientRect().height;
          $('.sticky-top').css('top', `${cookieBarHeight}px`);
        }
      } else {
        $('.rek-price:not(.static)').removeClass('sticky-top');
        rekPriceTop = null;
      }
    });

    // add ellipsis if Ad title is too long
    $.fn.renderedText = function onRender() {
      let s = this.text();
      const o = s;

      while (s.length && this[0].scrollWidth > this.innerWidth()) {
        s = s.slice(0, -1);
        this.innerHTML(`${s}&hellip;`);
      }
      this.text(o);
      return s;
    };

    priceBoxDescElement = document.getElementById('price-box-desc');
    const elementS = {
      container: document.getElementById('price-box-s'),
      circle: document.getElementById('price-box-s-circle'),
      text: document.getElementById('price-box-s-text'),
      desc: i18next.t('price_box_text_small'),
    };
    const elementM = {
      container: document.getElementById('price-box-m'),
      circle: document.getElementById('price-box-m-circle'),
      text: document.getElementById('price-box-m-text'),
      desc: i18next.t('price_box_text_medium'),
    };
    const elementL = {
      container: document.getElementById('price-box-l'),
      circle: document.getElementById('price-box-l-circle'),
      text: document.getElementById('price-box-l-text'),
      desc: i18next.t('price_box_text_large'),
    };
    const elementXL = {
      container: document.getElementById('price-box-xl'),
      circle: document.getElementById('price-box-xl-circle'),
      text: document.getElementById('price-box-xl-text'),
      desc: i18next.t('price_box_text_xlarge'),
    };
    elements = [elementS, elementM, elementL, elementXL];
    elementsMap = {
      S: elementS,
      M: elementM,
      L: elementL,
      XL: elementXL,
    };
  }

  // time slots creation from 8:00 to 24:00
  function createTimeSlots({
    startDate,
    day,
    slotLengthHr = 1,
    startHr = 8,
    endHr = 24,
    minSlotFracRemaining = 1,
    openDays = [],
    endHrPerDay = {},
    futureDaysMax = 1,
  }) {
    const slots = [];
    const dayMax = new Date();
    dayMax.setDate(startDate.getDate() + futureDaysMax + 1);
    dayMax.setHours(0, 0, 0, 0);
    let firstDay = startDate;
    const currentTs = firstDay.getTime();

    if (openDays.length) {
      firstDay = getNextOpenDay(firstDay, openDays);
    }

    if (firstDay.getTime() >= dayMax.getTime()) {
      return 0;
    }

    let dayAfter = new Date();
    dayAfter.setDate(firstDay.getDate() + 1);

    if (openDays.length) {
      dayAfter = getNextOpenDay(dayAfter, openDays);
    }

    if (day === 'tomorrow' && dayAfter.getTime() >= dayMax.getTime()) {
      return 0;
    }

    const d = day === 'tomorrow' ? dayAfter : firstDay;

    let endHour = endHr;
    if (endHrPerDay[d.getDay()]) {
      endHour = endHrPerDay[d.getDay()];
    }

    for (let ith = startHr; ith < endHour; ith += slotLengthHr) {
      const ts = d.setHours(ith, 0, 0, 0);
      const tsWithMargin = ts + slotLengthHr * 3600000 * (1 - minSlotFracRemaining);
      if (tsWithMargin >= currentTs) {
        const slot = {};
        slot.ts = ts;
        slot.startHr = d.getHours();
        slot.endHr = slot.startHr + slotLengthHr;
        slots.push(slot);
      }
    }

    const dayLabel = d.toLocaleDateString(i18next.language, { weekday: 'long' });

    const startOfFirstDay = new Date();
    startOfFirstDay.setDate(firstDay.getDate());
    startOfFirstDay.setHours(0, 0, 0, 0);

    const tomorrow = new Date();
    tomorrow.setDate(startDate.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);

    const afterTomorrow = new Date();
    afterTomorrow.setDate(startDate.getDate() + 2);
    afterTomorrow.setHours(0, 0, 0, 0);

    const headerElm = document.getElementById(day);

    if (headerElm && day === 'today' && startOfFirstDay.getTime() === tomorrow.getTime()) {
      headerElm.innerHTML = i18next.t('tomorrow');
    } else if (headerElm && day === 'today' && startOfFirstDay.getTime() > tomorrow.getTime()) {
      headerElm.innerHTML = (dayLabel[0] || '').toUpperCase() + dayLabel.substr(1);
    }

    if (headerElm && day === 'tomorrow' && dayAfter.getTime() > afterTomorrow.getTime()) {
      headerElm.innerHTML = (dayLabel[0] || '').toUpperCase() + dayLabel.substr(1);
    }

    createTimeSlotElements(day, slots);

    return slots.length;
  }

  // creates HTML checkboxes dynamically from time-slots
  function createTimeSlotElements(day, slots) {
    const el = document.getElementById(`slot-container-${day}`);

    const { width, height } = getDimension(`slot-container-${day}`, 4);
    const pattern = /^[0-9]$/; // only 1 digit

    slots.forEach(item => {
      const newElementContainer = document.createElement('LABEL');
      const newElement = document.createElement('INPUT');
      const newCheckboxElementLabel = document.createElement('DIV');
      newElement.type = 'checkbox';

      // add 0 as prefix for single digit hours
      if (pattern.test(item.startHr)) {
        item.startHr = `0${item.startHr}`; // eslint-disable-line
      }

      if (pattern.test(item.endHr)) {
        item.endHr = `0${item.endHr}`; // eslint-disable-line
      }

      newElement.name = `timeSlot_${item.ts}`;
      newElement.setAttribute('data-timeSlot', item);
      if (tiptapp && tiptapp.main && tiptapp.main.handleInputUpdateEvent) {
        newElement.addEventListener('change', tiptapp.main.handleInputUpdateEvent);
      }

      newCheckboxElementLabel.innerText = `${item.startHr}-${item.endHr}`;
      newElement.className = 'time-slot';
      newElement.value = item.ts;

      newElementContainer.className = 'time-slot-label';

      newCheckboxElementLabel.setAttribute(
        'style',
        `width: ${width}px;
        min-width: ${width}px;
        min-height: ${height}px;
        height: ${height}px;
        `
      );

      newElementContainer.appendChild(newElement);
      newElementContainer.appendChild(newCheckboxElementLabel);
      el.appendChild(newElementContainer);
    });
  }

  // returns dimensions for each timeslots based on screen width
  function getDimension(containerId, itemsInRow) {
    const dimension = {};
    const containerWidth = document.getElementById(containerId).offsetWidth;
    dimension.width = containerWidth / itemsInRow - 9;
    dimension.height = dimension.width / 2;
    return dimension;
  }

  // reset address input field
  function resetInput(fieldId) {
    $(`#${fieldId}`)
      .val('')
      .focus();
    tiptapp.validation.validateAddress(fieldId);
  }

  function toggleErrorModal(toggle, errorText) {
    const element = document.getElementById('dim-screen');
    const textElement = document.getElementById('server-error-message');

    if (toggle) {
      textElement.innerHTML = errorText || i18next.t('server_error_message');
      element.style.display = 'flex';
    } else {
      element.style.display = 'none';
    }

    const supportLinkElm = document.getElementById('server-error-support-link');
    if (supportLinkElm) {
      supportLinkElm.addEventListener('click', e => {
        e.stopPropagation();
        try {
          Intercom('show');
        } catch (err) {
          console.warn(`Failed to open Intercom. ${err}`);
        }
      });
    }
  }

  function pickPricebox(elementId) {
    elements.forEach(e => {
      if (e.container.id === elementId) {
        e.circle.classList.add('price-box-size-circle-chosen');
        e.text.classList.add('price-box-size-text-chosen');
        priceBoxDescElement.innerHTML = e.desc;
        e.container.classList.add('price-box-chosen');
        return;
      }
      e.circle.classList.remove('price-box-size-circle-chosen');
      e.text.classList.remove('price-box-size-text-chosen');
      e.container.classList.remove('price-box-chosen');
    });
  }

  function getSelectedPriceBox() {
    return $('input[name=priceGroup]:checked').val();
  }

  function pickInsurancePremium(elementId) {
    elements.forEach(e => {
      if (e.container.id === elementId) {
        e.circle.classList.add('insurance-premium-size-circle-chosen');
        e.text.classList.add('insurance-premium-size-text-chosen');
        priceBoxDescElement.innerHTML = e.desc;
        e.container.classList.add('insurance-premium-chosen');
        return;
      }
      e.circle.classList.remove('insurance-premium-size-circle-chosen');
      e.text.classList.remove('insurance-premium-size-text-chosen');
      e.container.classList.remove('insurance-premium-chosen');
    });
  }

  // Return user-selected insurance package
  function getSelectedInsurance() {
    const elm = $('input[name=insurancePremiumCents]:checked');
    return {
      premiumCents: elm.val(),
      insuredAmountCents: elm.attr('data-insured-amount'),
    };
  }

  // Return the user’s email address that they want to associate with the insurance
  function getInsuranceEmail() {
    return $('#insurance-user-email').val();
  }

  // Goto login screen if insurance is skipped
  $('#skip-insurance').click(function onClick() {
    tiptapp.analytics.trackEvent('web_insurance_form_cancel');
    tiptapp.setScreen('login-state');
    tiptapp.getLoginCode($('#ad-create-form').attr('action'), tiptapp.store.phone.get());
  });

  $('#waste-categories-back-btn').click(() => {
    tiptapp.setScreen('begin-state');
  });

  $('#waste-category-rules-back-btn').click(() => {
    tiptapp.setScreen('waste-categories-state');
  });

  /**
   * Update the specified size button's associated description.
   *
   * @param {string} name Name of the size button.
   */
  function setSelectedPriceBoxDescription(name) {
    const element = elementsMap[name];
    if (element && priceBoxDescElement) {
      priceBoxDescElement.innerHTML = element.desc;
    }
  }

  /**
   * Update the description assocated with the currently selected size button.
   */
  function updateSelectedPriceBoxDescription() {
    const value = getSelectedPriceBox();
    if (value) {
      setSelectedPriceBoxDescription(value);
    }
  }

  function getOwnerFee(referrerName, market) {
    const feeObject = (ownerFee[referrerName] || {})[market];
    return feeObject && feeObject.enabled ? feeObject.fee : 0;
  }

  function getNextOpenDay(today, openDays) {
    // Get the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const currentDay = today.getDay();

    // Find the next open day
    let nextOpenDay = -1;
    for (let index = 0; index <= 7; index += 1) {
      const nextDayIndex = (currentDay + index) % 7;
      if (openDays.includes(nextDayIndex)) {
        nextOpenDay = nextDayIndex;
        break;
      }
    }

    // Calculate the date of the next open day
    const daysUntilNextOpenDay = (nextOpenDay - currentDay + 7) % 7;
    const nextOpenDate = new Date(today.getTime() + daysUntilNextOpenDay * 24 * 60 * 60 * 1000);

    return nextOpenDate;
  }

  return publicInterface;
})();
