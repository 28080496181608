window.tiptapp = window.tiptapp || {};

tiptapp.coop = (function i() {
  const publicInterface = {
    init,
    getNearestStore,
  };

  let stores = [];

  function init() {
    return new Promise((resolve, reject) => {
      $('#ad-create-form-coop').submit(onFormSubmit);

      const timeSlotDay = tiptapp.store.timeSlotDay.get();
      if (timeSlotDay && timeSlotDay === 'tomorrow') {
        $('#today').hide();
      } else {
        $('#tomorrow').hide();
      }

      $.getJSON(`/json/coop_stores_${i18nlocale.split('-')[0]}.json`, data => {
        stores = data;
        resolve();

        initToAddress();
      }).fail(function() {
        console.log('error');
        reject();
      });
    });
  }

  function initToAddress() {
    try {
      const toAddressData = JSON.parse($('#to-address').attr('data-tiptappAddress'));
      tiptapp.store.address.set('toAddress', toAddressData);
      tiptapp.main.updateRecommendedPrice('toAddress');
      if (toAddressData.loc) {
        const nearestStore = getNearestStore([toAddressData.loc.lat, toAddressData.loc.lng]);
        if (nearestStore.name) {
          $('input[name=adTitle]').val(nearestStore.name);
        }
        if (nearestStore.imageName) {
          const imgUrl = `${window.location.origin || ''}/img/coop-storefronts/${nearestStore.imageName}`;
          $('#ad-preview-img').css('background-image', `url('${imgUrl}')`);
          gExternalAd.images = [imgUrl];
        }
        /* An alternative solution would be to provide hidden
         * input[name='fromAddress'] field which will be read by
         * tiptapp.getAddressInfo.
         */
        const fromAddressData = formatGooglePlaceToAddress(nearestStore);
        if (nearestStore.name) {
          fromAddressData.displayName = nearestStore.name;
        }
        tiptapp.store.address.set('fromAddress', fromAddressData);
      }
    } catch (error) {
      console.warn('Address data failed to be parsed: ', error);
    }

    //   tiptapp.validation.validateAddress(inputId);
  }

  function onFormSubmit(event) {
    event.preventDefault();

    $('#ad-create-form-coop input').attr('touched', 'true');

    const hasErrors = tiptapp.validation.validateOnPublish();
    if (hasErrors) return;

    const ad = tiptapp.getAdInfo();

    tiptapp.store.ad.set(ad);

    tiptapp.analytics.trackEvent('web_create_ad_form_continue');

    tiptapp.setScreen('loading-state');

    let phone = ad.to && ad.to.contactInfo ? ad.to.contactInfo.phone : ad.contactInfo ? ad.contactInfo.phone : null;

    ad.reference = String(gExternalAd.id);

    try {
      createAd(ad, phone);
    } catch (error) {
      tiptapp.blocket.toggleErrorModal(true, error.message);
      if (error.type === 'login') {
        tiptapp.setScreen('resend-code-state'); // TODO: if login error
      } else {
        tiptapp.setScreen('begin-state'); // TODO: if campaign code error
      }
    }
  }

  async function createAd(ad, phone) {
    const { user, authorization } = await loginCoop(phone, tiptapp.coopToken);

    const email = tiptapp.store.email.get();

    let referrerName;

    try {
      referrerName = gReferrerName || 'blocket';
    } catch (err) {
      referrerName = 'blocket';
    }

    const images = getImages();

    // const data = { ad, images, sessionToken: user.pass, userId: user._id, referrerName };
    const data = { ad, images, referrerName };
    if (email) {
      data.email = email;
    }

    data.ownerFee = tiptapp.blocket.getOwnerFee(referrerName, gMarket.countryCode);

    /* TODO: consider moving the publishAd function to a better namespace and
     * file than blocket-routes for less code duplication.
     */
    const { dynamicLink, item } = await publishAd(ad, images, user, email, authorization);

    if (item) {
      tiptapp.store.ad.set(item);
    }

    tiptapp.analytics.trackEvent('web_create_ad');

    if (!gExternalAd || !gExternalAd.id || !+gExternalAd.id) {
      sessionStorage.clear();

      tiptapp.saveUser({
        ...user,
        jwt: authorization,
      });
    }

    if (dynamicLink) {
      tiptapp.store.dynamicLink.set(dynamicLink);
    }

    tiptapp.setScreen('final-state');
  }

  async function loginCoop(phone, token) {
    try {
      const body = {
        fname: tiptapp.user.fname,
        lname: tiptapp.user.lname,
        phone: tiptapp.format.phone(phone),
      };

      const { user, authorization } = await $.ajax({
        type: 'POST',
        url: '/api/login/coop',
        headers: { authorization: token, ...tiptapp.main.getApiHeaders() },
        data: JSON.stringify(body),
        contentType: 'application/json',
      });

      tiptapp.saveUser({
        ...user,
        jwt: authorization,
      });

      return { user, authorization };
    } catch (error) {
      console.log(error);
      const response = error.responseJSON || {};
      throw { type: 'login', message: response.message };
    }
  }

  function getNearestStore(coordinates) {
    const storesWithDistance = stores.map(store => ({
      ...store,
      distance: metersBetween(coordinates[0], coordinates[1], store.geometry.location.lat, store.geometry.location.lng),
    }));

    const sortedStoresWithDistance = storesWithDistance.sort((a, b) => a.distance - b.distance);

    return sortedStoresWithDistance[0];
  }

  function metersBetween(lat1, lng1, lat2, lng2) {
    if (typeof lat1 !== 'number' || typeof lng1 !== 'number' || typeof lat2 !== 'number' || typeof lng2 !== 'number') {
      return NaN;
    }
    const earthDiameter = 12745600;
    const degToRadFactor = Math.PI / 180;
    const lat1rad = lat1 * degToRadFactor;
    const lon1rad = lng1 * degToRadFactor;
    const lat2rad = lat2 * degToRadFactor;
    const lon2rad = lng2 * degToRadFactor;

    const dlat = (lat2rad - lat1rad) / 2;
    const dlon = (lon2rad - lon1rad) / 2;
    const a = Math.sin(dlat) * Math.sin(dlat) + Math.sin(dlon) * Math.sin(dlon) * Math.cos(lat1rad) * Math.cos(lat2rad);

    const meters = earthDiameter * Math.asin(Math.sqrt(a));

    return meters;
  }

  return publicInterface;
})();
